import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';


@Injectable({
  providedIn: 'root'
})

export class AspProductDetailsService {
    constructor(
        private http: HttpClient,
      ) { }

    productDetails(garageKey, type, id){  
      let paramData = {      
          garageKey: garageKey
      };

      
      
      console.log('-----Get Product Details-----------');  
      if(type=='part-number'){
        paramData['partNumber'] = id;
        return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/spare/detailByPartNumber', paramData);
      }else{
        paramData['partKey'] = id;
        return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/spare/detail', paramData);
      }
    }

    getProductCompatability(partNumber){
      let param={
      };
      console.log('-----Get Product Details-----------');    
      return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}` + '/vehicle/getCompatibleVehicles/'+partNumber,  param);
    }
          

       
}
