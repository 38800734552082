<mat-toolbar class="p-0 mat-elevation-z1">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div *ngIf="!isMobile" class="h-100-p" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center" style="width: 55% !important;">

           
            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div class="delivery-header">
                <div class="delivery-info">
                  <span class="deliveryTime" [ngClass]="{'deliveryTimeMobile': isMobile}">Delivery in 90 minutes</span>
                </div>
            </div>
            <div class="SearchBarContainer" (click)="toggleSearch()" [ngClass]="{'SearchBarContainerMobile': isMobile}">
                <div class="SearchBarButton">
                  <div class="SearchBarIcon">
                    <i class="material-icons">search</i>
                  </div>
                  <div class="SearchBarPlaceholder">
                    <div class="SearchBarAnimation" *ngIf="!isInputVisible">
                      <div id="animationText-1" class="SearchBarAnimationText">Search By "Part Name"</div>
                      <div id="animationText-2" class="SearchBarAnimationText">Search By "Part Number"</div>
                      <div id="animationText-3" class="SearchBarAnimationText">Search By "Brand"</div>
                      <div id="animationText-4" class="SearchBarAnimationText">Search By "Category"</div>
                    </div>
              
                    <div *ngIf="isInputVisible" class="SearchInputContainer" (click)="$event.stopPropagation()">
                        <input
                          type="text"
                          placeholder="Type your search"
                          class="SearchInput"
                          [(ngModel)]="searchText"
                          (input)="filterSuggestions()"
                          (click)="toggleSearch()"
                        >
                        <button (click)="clearSearch($event)" class="button">
                          <i class="material-icons" style="font-size: 16px;">clear</i>
                        </button>
                
                        <!-- Suggestions Dropdown -->
                        <div #dropdown class="suggestions" *ngIf="suggestions.length > 0">
                          <div *ngFor="let suggestion of suggestions; let i = index" (click)="selectSuggestion(suggestion)">
                            <i class="material-icons" *ngIf="isFirstSuggestion(suggestion,i)" style="font-size: 14px; padding: 10px;">access_time</i>
                            <i class="material-icons" *ngIf="!isFirstSuggestion(suggestion,i)" style="font-size: 14px; padding: 10px;">search</i>
                            <span [innerHTML]="getHighlightedText(suggestion.value, searchText)"></span>

                          </div>
                        </div>
                      
                    </div>
                  </div>
                </div>
              </div>
        </div> 
        
        <!-- Change Garage -->
        <button *ngIf="!isMobile" class="garage-selected" (click)="loadorderdetails()">
            <div class="garage-icon"><mat-icon>build</mat-icon></div>              
            <div class="garage-name">{{spareSelectedGarageName}}</div>      
        </button>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <div *ngIf="!isMobile" fxHide.gt-sm class="toolbar-separator"></div>
            <!-- <button fxHide.gt-sm mat-icon-button (click)="openSearch()" class="fuse-search-bar-expander" aria-label="Expand Search Bar">
                <mat-icon class="s-24 secondary-text">search</mat-icon>
            </button> -->
           
            <!-- Wallet -->
            <div class="mywallet" *ngIf="!isMobile" >
                <button class="mywalletbtn" (click)="openSidebar()" style="background-color: #d7f5fc;color: #144579; font-weight: bold;height: 42px;">
                    <img src="../../../../assets/images/wallet/wallet_24dp_5F6368_FILL0_wght400_GRAD0_opsz24.svg" class="mywalletimg"> My Wallet
                  <!-- <ng-container *ngIf=availableWalletBalance > 0; else emptyWallet"> -->
                    <!-- <div class="right-wallet">My Wallet</div> -->
                    <div class="right-wallet-balance" *ngIf="availableWalletBalance > 0">₹ {{availableWalletBalance}}</div>

                    
                  <!-- </ng-container> -->
                  <!-- <ng-template #emptyWallet>
                    My Wallet
                  </ng-template> -->
                </button>
              </div>

            <!-- <div class="toolbar-separator"></div> -->
           
            <!-- Language Switcher -->
            <!-- <img class="translate-img" [matMenuTriggerFor]="menu" src="{{translateImg}}" />
            <mat-menu #menu="matMenu">
                <button mat-menu-item *ngFor="let language of translate.getLangs()" (click)="translateLanguageTo(language)">
                    <img *ngIf="language=='en'" src="{{langSwitchImg[0].en}}" />
                    <img *ngIf="language=='hi'" src="{{langSwitchImg[0].hi}}" />
                </button>
            </mat-menu> -->
            <!-- <select #selLang (change)="translateLanguageTo(selLang.value)">
                <option *ngFor="let language of translate.getLangs()" [value]="language">{{ language }}</option>
            </select> -->
            <!-- <div class="toolbar-separator"></div> -->
            <!-- End Language Switcher -->

            <!-- Cart  -->




            <!-- <div class="cart_btn">
                <div class="count_notified">
                    <p class="cart_count_num">{{cartCount}}</p>
                </div>
                <button class="open_btn" (click)="openCart()">
                    <mat-icon>shopping_cart</mat-icon>My Cart
                </button>
            </div>  -->

            <!-- Cart -->
            <div class="cart_btn" *ngIf="!isMobile">
                
                <button class="open_btn" (click)="openCart()">
                    <mat-icon>shopping_cart</mat-icon>
                    <div class="left">
                        
                        <div class="cart_count_num">
                            <div *ngIf="cartCount > 0">
                                {{cartCount}} items
                                <div class="right-amount-carts">
                                    ₹{{cartGrandTotal | number:'1.2-2'}}
                                   
                                </div>
                            </div>
                            <div *ngIf="cartCount <= 0">
                                My Cart
                            </div>
                        </div>
                    </div>
                </button>
            </div>
             <!-- <div class="toolbar-separator"></div>  -->
            <!-- Cart End -->






            
            <!-- <button mat-button [matMenuTriggerFor]="userMenu" class="user-button" style=" margin-right: 10px;">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="avatar mr-0 mr-sm-16" [src]="userData.img" 
                        onerror="this.onerror=null;this.src='https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png'">
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{ userData.name }}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item (click)="openProfile()">
                    <mat-icon>account_circle</mat-icon>
                    <span>Profile</span>
                </button>
                <button mat-menu-item class="" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>
                <button mat-menu-item [matMenuTriggerFor]="helpSection">
                    <mat-icon>help_outline</mat-icon>
                    <span>Help</span>
                </button>
            </mat-menu>
            <mat-menu #helpSection="matMenu" [overlapTrigger]="false">
                <div class="help_class">
                    <h4 class="helo_hedng">HELP</h4>
                    <hr>
                    <div class="email_help">
                        <p class="mail_sect">
                            <mat-icon>mail</mat-icon>
                        </p>
                        <p class="mail_sect"><a href="mailto:garage-help@getpitstop.com">garage-help@getpitstop.com</a></p>
                    </div>
                </div>
            </mat-menu>
            <div class="toolbar-separator" fxHide.lt-md></div> -->

            
            <!-- <button mat-button fxHide.lt-md class="h-64 w-64" *ngIf="!isfullscreen" (click)="openFullscreen()">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon class="s-32">fullscreen</mat-icon>
                </div>
            </button>
            <button mat-button fxHide.lt-md class="h-64 w-64" *ngIf="isfullscreen" (click)="closeFullscreen()">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon class="s-32">fullscreen_exit</mat-icon>
                </div>
            </button> -->
            <!-- <div class="toolbar-separator"></div>

            <button mat-button fxHide fxShow.gt-xs
                    class="language-button"
                    [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

            </mat-menu>

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <button mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button> -->
        </div>

        <!-- Mobile  -->
        <div *ngIf="isMobile" class="mobile-menu">
            <button mat-icon-button class="navbar-toggle-button" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="logo" (click)="goToHome()">
                <img class="logo-icon" src="assets/images/logos/mobile_autoverse_logo.svg">
                <span class="logo-text">AUTOVERSE</span>
            </div>


            <!-- Build button to trigger garage menu -->
            <button mat-button [matMenuTriggerFor]="garageMenu" class="garage-selected" style="margin-right: 10px;">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon class="garage-icon-mobile-view">build</mat-icon>
                    <!-- <span class="ml-8">Garage</span> -->
                </div>
            </button>

            <!-- Garage selection menu -->
            <mat-menu #garageMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item (click)="loadorderdetails()">
                    <!-- <mat-icon >garage</mat-icon> -->
                    <div class="garage-name">{{spareSelectedGarageName}}</div>      
                </button>
            </mat-menu>


            <div class="mywallet">
                <button class="mywalletbtn" (click)="openSidebar()" >
                    <img src="../../../../assets/images/wallet/wallet_24dp_5F6368_FILL0_wght400_GRAD0_opsz24.svg" class="mywalletimg" alt="Wallet Icon">
                </button>
            </div>

            <div class="cart_btn_mobile">                
                <button class="open_btn_mobile" (click)="openCart()">
                    <mat-icon class="cart-icon-mobile-view">shopping_cart</mat-icon>
                    <div class="cart_count_mobile"><div *ngIf="cartCount > 0" class="cart_count">{{cartCount}}</div></div>
                </button>
            </div>
        </div>

    </div>
</mat-toolbar>
<div class="search-results-autocomplete" *ngIf="submitted">
    <div class="table-container mat-elevation-z8">
        <div class="page-title" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="mat-headline text-left m-0 p-12" fxLayout="column" fxLayoutAlign="center start">Search
                Results</span>
            <div class="close-btn text-right p-12 pt-0 pr-8" fxLayout="column" fxLayoutAlign="center end" (click)="closeSearchResults()">
                <mat-icon>close</mat-icon>
            </div>
        </div>
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        <table class="clickable" mat-table [dataSource]="searchSource" matSort>
            <ng-container matColumnDef="orderId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>ORDER ID</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row"> <strong>{{row.orderId}}</strong> </td>
            </ng-container>

            <ng-container matColumnDef="registration">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>VEHICLE</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    <strong>{{row.registration | uppercase}}</strong>
                    <div><small>{{row.vehicle | uppercase}}</small> (<small>{{row.fuelType | lowercase}}</small>)</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="orderStartTime">
                <th fxHide.lt-md mat-header-cell *matHeaderCellDef mat-sort-header> <strong>APPOINTMENT</strong> </th>
                <td fxHide.lt-md mat-ripple mat-cell *matCellDef="let row">
                    {{row.orderStartTime == '' ? '-' : row.orderStartTime}}
                </td>
            </ng-container>

            <ng-container matColumnDef="orderEndTime">
                <th fxHide.lt-md mat-header-cell *matHeaderCellDef mat-sort-header> <strong>EXPECTED DELIVERY</strong>
                </th>
                <td fxHide.lt-md mat-ripple mat-cell *matCellDef="let row">
                    {{row.orderEndTime == '' ? '-' : row.orderEndTime}}
                </td>
            </ng-container>

            <ng-container matColumnDef="grandTotal">
                <th fxHide.lt-sm mat-header-cell *matHeaderCellDef mat-sort-header> <strong>AMOUNT</strong> </th>
                <td fxHide.lt-sm mat-ripple mat-cell *matCellDef="let row">
                    {{row.grandTotal == 0 ? '-' : row.grandTotal | currency:"&#8377; "}}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>STATUS</strong> </th>
                <td mat-ripple mat-cell *matCellDef="let row">
                    <mat-chip-list>
                        <mat-chip class="{{row.status}} mat-chip mat-standard-chip mat-chip-selected" selected [innerHTML]="row.status"></mat-chip>
                    </mat-chip-list>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="searchOrdersColumns;sticky: false"></tr>
            <tr mat-row *matRowDef="let row; columns: searchOrdersColumns;" (click)="openDetails(row.orderId)"></tr>
        </table>
        <div *ngIf="noData && !loading">
            <div class="message-box error mt-16 text-center font-weight-700">
                No Data to display
            </div>
        </div>
        <mat-paginator [pageSizeOptions]="[5]" showFirstLastButtons></mat-paginator>
    </div>
</div>
<div class="notify_overlay" *ngIf="showNotifaction" #_eref>
    <button class="cls_btn" (click)="closeNotification()"><i class="material-icons">
        close
        </i></button>
    <div class="notify_cotainer">
        <ul *ngIf="!noNotification">
            <li *ngFor="let notifyMsg of notificationData" (click)="confirmedOrderDetails(notifyMsg.orderId)">{{notifyMsg.message}} </li>
        </ul>
        <p class="notification" *ngIf="noNotification"> No Data to display </p>
    </div>
    <div class="pagination_sec" *ngIf="showPaginations">
        <button (click)="prevNotify()" [disabled]="showPrevButton">Previous</button>
        <button (click)="nextNotify()">Next</button>
    </div>
</div>

<mat-sidenav-container>
    <div class="overlay" [class.visible]="sidebarOpen" (click)="closeSidebar()" ></div>
    <div [class.sidebar-open]="sidebarOpen" class="mywallet-sidebar">
        <!-- {{walletInfo.transactions | json}} -->
        <div class="wallet-sidebar-content">
            <div class="wallet-heading">
                <mat-icon class="wallet-mat" (click)="closeSidebar()" style="cursor: pointer; margin-top: 15px;">arrow_back</mat-icon>
                <h3 style="font-weight: 900;margin-left: 5px; margin-top: 15px;">Autoverse Wallet </h3>
                <button class="know-more-btn" (click)="openDrawer()">KNOW MORE</button>
            </div>
            <div class="balance-section">
                <div class="balance-details">
                    
                    <div class="balance-amount">{{ walletInfoData.totalAmount}}</div> <!-- Amount to display -->
                    <div class="balance-text">Total Available Balance</div>
                </div>
                <div class="balance-icon">
                    <img src="../../../../assets/images/wallet/wallet_24dp_5F6368_FILL0_wght400_GRAD0_opsz24.svg" alt="wallet icon">
                </div>
            </div>
            <!-- *ngIf="walletInfoData.transactions.length>0" -->
            <div class="transaction-list" *ngIf="walletInfoData?.transactions?.length>0" >
                <div class="transaction" *ngFor="let transaction of walletInfoData.transactions">
                    <div class="transaction-header">
                        <span 
                            class="transaction-type" 
                            [ngClass]="transaction.type === 'debit' ? 'debit' : 'credit'">
                            <mat-icon>
                                {{ transaction.type === 'debit' ? 'call_made' : 'call_received' }}
                            </mat-icon>
                        </span>
                        <span class="transaction-type received">
                            {{ transaction.type === 'debit' ? 'Paid To' : 'Received From' }}
                        </span>
                        <span 
                            class="transaction-amount received" 
                            [ngClass]="transaction.type === 'debit' ? 'amount-debit' : 'amount-credit'"> ₹
                            {{ transaction.amount }}
                        </span>
                    </div>
                
                    <div class="transaction-details">
                        <div class="transaction-info-header">
                            <div style="width: 100%; float: left; font-weight: bold; color: black; font-size: 14px;">
                                {{ transaction.subTitle }}
                            </div>
                            <!-- Show the "Expired" label if the transaction is expired and type is not 'debit' -->
                            <span *ngIf="transaction.expired && transaction.type !== 'debit'" class="expired-label">Expired</span>
                        </div>
                        <div style="font-size: 11px; width: 100%;">
                            {{ transaction.title }} &nbsp; {{ transaction.createdOn | date:'MMM dd, yyyy, hh:mm a': 'GMT+1060'}}
                        </div>
                        <!-- Show status based on expiration, but only if type is not 'debit' -->
                        <div class="transaction-status" *ngIf="transaction.type !== 'debit'">
                            <ng-container *ngIf="transaction.expired; else expiresOnTemplate">
                                Expired on {{ transaction.expiresOn | date:'MMM dd, yyyy': 'GMT+1060' }}
                            </ng-container>
                            <ng-template #expiresOnTemplate>
                                Expires on {{ transaction.expiresOn | date:'MMM dd, yyyy': 'GMT+1060' }}
                            </ng-template>
                        </div>
                    </div>
                
            </div>
            
            
                <!-- <div class="transaction">
                    <div class="transaction-header">
                        <mat-icon class="transaction-icon-paid">call_made</mat-icon>
                        <span class="transaction-type paid">Paid To</span>
                        <span class="transaction-amount paid">(rs)</span>
                    </div>
                    <div class="transaction-details">
                        <div class="transaction-info">
                            <div class="transaction-info-header">
                                <div>Order Payment Done.</div>
                                <span class="expired-label">Expired</span>
                            </div>
                            <div>Order No. #GO - num &nbsp; date</div>
                            <div class="transaction-status">Expired on date</div>
                        </div>
                    </div>
                </div> -->
            </div>
            <button *ngIf="walletInfoData.totalAmount > 0" class="floater-btn" (click)="redeemNow()">Redeem Now</button>
            <div class="transaction-money" *ngIf="walletInfoData?.transactions?.length==0">
                <div class="transaction-heading">Currently You don't have any Wallet Transaction</div> 
                <div class="transaction-sub-heading" >Stay tuned for updates</div>
            </div>
        </div>
    </div>
</mat-sidenav-container>


<!-- Bottom Sheet Content -->
<!-- <div class="overlay" [class.visible]="sidebarOpen" (click)="closeDrawer()" ></div> -->
<div class="wallet-bottom-sheet" [class.show]="isDrawerVisible">
    <button class="close-btn" (click)="closeDrawer()">×</button>
    <img src="../../../../assets/images/gift_images.jpeg" alt="Gift Icon" class="gift-icon">
    {{orderDeliverCredit}}
    <div class="quality-heading">{{ knowMoreHeading || 'No heading available' }}</div>
    <ul>
        <li *ngFor="let point of knowMorePoints; let i = index">
            <label class="point-label">
                <input type="radio" [value]="'option' + (i + 1)" name="walletOption">
                <!-- Display the point from the API -->
                {{ point }}
            </label>
        </li>
    </ul>
    
</div>

<div #dynamicContainer></div>