import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspMyReportsService {

  constructor(private http: HttpClient,) { }

  getDashboard(garageKey,userKey,monthly){
    let paramData = {  
      garageKey: garageKey,
      userKey: userKey,
      monthly: monthly
  };
  console.log("----dash----");
  return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/garage/GarageDashboard', paramData);

  }
}
