<div class="bottom-sheet-container" >\
    <div>
<h1 class="header"> Want to Avoid Parts mismatch? </h1>
<i class="material" (click)="closeSheet()" >close</i>
</div>
<img src="../../../../assets/images/avoidPartsMissmatch/mismatch.jpg" class="images">

<img src="../../../../assets/images/avoidPartsMissmatch/benefits.png" class="images1">
<button class="buttonfind" (click)="navigate()">
    Yes, Find My Car
</button>
<p class="countinue" (click)="closeSheet()"> Continue without car selection</p>

</div>