<div class="page-layout-blank-fullwidth" fusePerfectScrollbar>
        <div  [attr.id]="isMobile ? 'vehicleMobile' : 'vehicle'" class="inner-scroll" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center">
    <div class="SearchBarContainer" (click)="toggleSearch()" [ngClass]="{'SearchBarContainerMobile': isMobile}" *ngIf="isMobile">
                <div class="SearchBarButton">
                  <div class="SearchBarIcon">
                    <i class="material-icons">search</i>
                  </div>
                  <div class="SearchBarPlaceholder">
                    <div class="SearchBarAnimation" *ngIf="!isInputVisible">
                      <div id="animationText-1" class="SearchBarAnimationText">Search By "Part Name"</div>
                      <div id="animationText-2" class="SearchBarAnimationText">Search By "Part Number"</div>
                      <div id="animationText-3" class="SearchBarAnimationText">Search By "Brand"</div>
                      <div id="animationText-4" class="SearchBarAnimationText">Search By "Category"</div>
                    </div>
              
                    <div *ngIf="isInputVisible" class="SearchInputContainer" (click)="$event.stopPropagation()">
                        <input
                          type="text"
                          placeholder="Type your search"
                          class="SearchInput"
                          [(ngModel)]="searchText"
                          (input)="filterSuggestions()"
                          (click)="toggleSearch()"
                        >
                        <button (click)="clearSearch($event)" class="button">
                          <i class="material-icons" style="font-size: 16px;">clear</i>
                        </button>
                
                        <!-- Suggestions Dropdown -->
                        <div #dropdown class="suggestions" *ngIf="suggestions.length > 0">
                          <div *ngFor="let suggestion of suggestions; let i = index" (click)="selectSuggestion(suggestion)">
                            <i class="material-icons" *ngIf="isFirstSuggestion(suggestion,i)" style="font-size: 14px; padding: 10px;">access_time</i>
                            <i class="material-icons" *ngIf="!isFirstSuggestion(suggestion,i)" style="font-size: 14px; padding: 10px;">search</i>
                            <span [innerHTML]="getHighlightedText(suggestion.value, searchText)"></span>

                          </div>
                        </div>
                      
                    </div>
                  </div>
                </div>
              </div>
        
        <div id="vehicle-intro" fxFlex>
            
            
            <div class="title" [@animate]="{value:'*',params:{delay:'650ms',y:'25px'}}" [ngClass]="{'titleMobile': isMobile}">
                Welcome to  The <strong>  Universe of Auto Parts</strong>
            </div>
            <div class="description" [@animate]="{value:'*',params:{delay:'700ms',y:'25px'}}">
                
                <strong>Timely delivery of genuine spare parts, every time!</strong>
            </div>
        </div>
        <div id="vehicle-form-wrapper" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">
            <div id="vehicle-form" *ngIf="!vehicleInfo" style="margin-top: -40px;">
                <form [class.loading]="loading" name="vehicleForm" [formGroup]="vehicleForm" (ngSubmit)="onSubmit()" novalidate>
                    <div class="enter">
                        <mat-label for="regNo">Enter your car number</mat-label>
                    </div>
                    <mat-form-field appearance="outline" style="font-size: larger;">
                        <div style="display: flex; align-items: center;">
                            <div class="left-section"  >
                                <img src="../../../../assets/images/home-page-reg-number/Group 16(1).png" alt="Car" width="20" height="10">
                                <span class="ind" >IND</span>
                            </div>
                            <div class="registration-number">
                                <input id="regNo" matInput formControlName="regNo" autocomplete="off" placeholder="KA 01 MY97**" maxlength="12" style="font-weight: 800; width: 98%;">
                            </div>
                            
                        </div>
                        <mat-error *ngIf="vehicleForm.get('regNo').hasError('required')">
                            Registration Number is required
                        </mat-error>
                    </mat-form-field>
                    <button mat-raised-button color="accent" class="submit-button" aria-label="VEHICLE" [disabled]="vehicleForm.invalid">
                        FIND MY CAR
                    </button>
                </form>

                <div class="separator" [ngClass]="{'separatorMobile': isMobile}">
                    <span class="text">Or</span>
                </div>

                <h4 class="text-center" [ngClass]="{'text-centerMobile': isMobile}" >Start with your car make</h4>
                <div [ngClass]="{'popularMMobile': isMobile}">
                <div class="popularMakes" *ngFor="let make of makeList" (click)="selectMake(make.make)">
                    <img class="img-fluid make-image" src="{{make.imageUrlPath}}" />
                </div>
            </div >
                <div class="popularMakes viewAll" style="padding: 24px 10px;" (click)="goToMake()">
                    View All
                </div>
                <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            </div>

            <div [attr.id]="isMobile ? 'vehicle-infoMobile' : 'vehicle-info'" *ngIf="vehicleInfo">
                <mat-icon class="car-icon">directions_car</mat-icon>
                <h3>Confirm your car details</h3>
                <div class="vehicle-details-row">
                    <div class="left"><mat-icon>check_circle</mat-icon>Make</div>
                    <div class="right">{{vehicleData.make}}</div>
                </div>
                <div class="vehicle-details-row">
                    <div class="left"><mat-icon>check_circle</mat-icon>Model</div>
                    <div class="right">{{vehicleData.model}}</div>
                </div>
                <div class="vehicle-details-row">
                    <div class="left"><mat-icon>check_circle</mat-icon>Variant</div>
                    <div class="right">{{vehicleData.variant}}</div>
                </div>
                <div class="vehicle-details-row">
                    <div class="left"><mat-icon>check_circle</mat-icon>MFG Year</div>
                    <div class="right">{{vehicleData.year}}</div>
                </div>
                <div class="vehicle-details-row">
                    <div class="left"><mat-icon>check_circle</mat-icon>Fuel</div>
                    <div class="right">{{vehicleData.fuel}}</div>
                </div>
                <div class="vehicle-details-row">
                    <div class="left"><mat-icon>check_circle</mat-icon>VIN</div>
                    <div class="right">{{vehicleData.vin}}</div>
                </div>

                <button mat-raised-button color="accent" class="confirm-btn" aria-label="Confirm" (click)="confirmCar()">
                    Confirm
                </button>
                <button mat-raised-button color="accent" class="change-car-button" aria-label="Change Car" (click)="changeCar()">
                    Change Car
                </button>
            </div>
        </div>
    </div>

    <mat-card class="mat-elevation-z8 mt-8 p-8 home p-12"  [ngClass]="{'homeMobile': isMobile}">
        <mat-card-header>
            <mat-card-title  [ngClass]="{'CategoriesMobile': isMobile}">Shop By Categories</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div *ngIf="veiwMoreCat" fxLayout="column" fxLayout.xs="column" style="padding: 10px;">
                <div fxLayout="row wrap" fxLayoutGap="10px">
                    <div *ngFor="let cat of categories; let i = index" fxFlex="23.7%" [ngClass]="{'CategoriesInsideMobile': isMobile}">
                        <mat-card *ngIf="i <= 7" (click)="sparesList(cat.name, 'categorySpares')">
                            <img src="{{cat.imageUrl}}"  [ngClass]="{'CategoriesImgMobile': isMobile}">
                            <div class="cat_name"  [ngClass]="{'cat_nameMobile': isMobile}">{{cat.name}}</div>
                        </mat-card>
                    </div>
                </div>
                <button mat-button (click)="getAllCategories()" class="view-more-cat" [ngClass]="{'view-more-catMobile': isMobile}">View More</button>
            </div>

            <div *ngIf="veiwLessCat" fxLayout="column" fxLayout.xs="column" style="padding: 10px;">
                <div fxLayout="row wrap" fxLayoutGap="10px">
                    <div *ngFor="let cat of allCategories; let i = index" fxFlex="23.7%" [ngClass]="{'CategoriesInsideMobile': isMobile}">
                        <mat-card (click)="sparesList(cat.name, 'categorySpares')">
                            <img src="{{cat.imageUrl}}" [ngClass]="{'CategoriesImgMobile': isMobile}">
                            <div class="cat_name" [ngClass]="{'cat_nameMobile': isMobile}">{{cat.name}}</div>
                        </mat-card>
                    </div>
                </div>
                <button mat-button (click)="viewLessBrands()" class="view-less-cat" [ngClass]="{'view-less-catMobile': isMobile}">View Less</button>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="mat-elevation-z8 mt-8 p-8 home p-12" [ngClass]="{'homeMobile': isMobile}">
        <mat-card-header>
            <mat-card-title [ngClass]="{'CategoriesMobile': isMobile}">Popular Brands</mat-card-title>
            <span class="view-all-brands" (click)="goToAllBrands()">View All</span>
        </mat-card-header>

        <mat-card-content>
            <div fxLayout="column" fxLayout.xs="column" style="padding: 10px;">
                <div fxLayout="row wrap" fxLayoutGap="10px">
                    <div *ngFor="let brand of brands; let i = index" fxFlex="23.7%" [ngClass]="{'CategoriesInsideMobile': isMobile}">                        
                        <mat-card *ngIf="i<=7" (click)="sparesList(brand.name, 'brandSpares', brand.imageUrl,brand.key)">
                            <img src="{{brand.imageUrl}}" [ngClass]="{'CategoriesImgMobile': isMobile}">
                            <div class="brand_name" [ngClass]="{'cat_nameMobile': isMobile}">{{brand.name}}</div>
                        </mat-card>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
