<div class="order-success-sec">
    <img src="{{successIcon}}" >
    <span class="close-button" (click)="closePopup()">✖</span>
    <h3>Order Created!</h3>
    <p class="order-label">Order ID <span class="order-id">#{{order.orderNumber}}</span></p>
    <!-- <p class="date-text"><span>{{order.orderDate | date: 'MMM dd yyyy hh:mm a'}}</span></p> -->
    <p class="date-text">{{order.orderDate}}</p>

    <button class="goto-order" (click)="goToOrder()">Track My Order</button>
</div>
