import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'app/main/globals'; // Adjust the path if needed
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SideBarJobcardService {
  bookingId: any;
  constructor(private http: HttpClient) {}

  regDecode(regNo: string) {
    // Assuming that regNo is passed correctly to the API endpoint
    console.log('-----Registration Decode-----------');
    return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}/vehicle/decode/${regNo}`);
  }

  createbookingId(
    bookingId:number,
    garageKey: string,
    name: string,
    odometer:number,
    phoneNumber: number,
    registrationNumber: string,
    rowKey:string,
    userId:string,
    variantKey: string
  ):Observable<any> {
  const paramData={
    bookingId:bookingId,
    garageKey: garageKey,
    name: name,
    odometer:odometer,
    phoneNumber: phoneNumber,
    registrationNumber: registrationNumber,
    rowKey:rowKey,
    userId:userId,
    variantKey: variantKey
  };
  console.log('name',name);
  console.log('phone',phoneNumber);
  console.log('rowKey',rowKey);
  console.log('variant',variantKey);




    console.log("-------------Booking ID---------------------");
      return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/booking/v1/jobCard/create`,paramData);
    
  }
}



//   createBooking(garageKey: string, name: string, phoneNumber: string) {
//     const paramData = {
//       garageKey: garageKey,
//       name: name,
//       phoneNumber: phoneNumber,
//       userId: 'f15c9e1c7e3eb40a1f1f420028c11d83',
//     };
//     console.log('Creating booking with data:', paramData);
//     return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/booking/v1/create', paramData);
//   }

// }


