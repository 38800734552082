<h2 mat-dialog-title>Save Coordinates</h2>

<mat-dialog-content>
  <!-- Form with formGroup -->
  <form [formGroup]="updateCatalogCords">
    <!-- ID Input Field at the Top -->
    <mat-form-field *ngIf="data.showInput">
      <input 
        matInput 
        formControlName="id"
        required
        type="number"
      >
      <mat-error *ngIf="updateCatalogCords.get('id').invalid && updateCatalogCords.get('id').touched">
        ID is required.
      </mat-error>
    </mat-form-field>
    
    <!-- Message Display -->
    <p>{{ data.message }}</p>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onConfirm()">Save</button>
</mat-dialog-actions>
