import { Injectable } from '@angular/core';
import { GlobalVariable } from 'app/main/globals';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AspRequestDetailsService {
  approveRequest: any;

  constructor(
    private http: HttpClient,
  ) { }


  requestDetails(garageKey, requestId, userKey){
    let paramData = {      
      garageKey: garageKey,
      requestId: requestId,
      userKey: userKey,
      
    };

    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/spare/request/details', paramData);
  }

  approveQuote(garageKey, spareRequestItems, userKey) {
    let paramData = {      
      garageKey: garageKey,
      spareRequestItems: spareRequestItems,
      userKey: userKey,
      status: 4
    };
    // console.log(paramData)
    // console.log("--------approve quotation----------")
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/spare/request/approve`, paramData);
  }

  submitData(payload: any) {
    const paramData=payload;
    console.log("paramData",JSON.stringify(paramData))
    return this.http.post(`${GlobalVariable. BASE_API_URL_SPARES}/garage/customer/quote/create`, paramData);
}
orderList(userKey, garageKey){
  let paramData = {      
    userKey: userKey,
    garageKey: garageKey,
  }

  console.log('-----Get Order List-----------');    
  return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/v2/order/getAllOrders`, paramData);

}
submitDataUpdate(payload: any){
  const paramData=payload;
  console.log('-----submitDataUpdate-----------');    
  return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}/garage/customer/quote/update`, paramData);

}
}
