import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { GlobalVariable } from 'app/main/globals';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AspLoginService {
  paramData: any;


  constructor(
    private http: HttpClient, 
    private router: Router,
  ) { }

  getOTP(mobile) {
    this.paramData = {
      mobile: mobile,
      // device: `${GlobalVariable.DEVICE}`
    };
    
    console.log('-----Request OTP-----------');
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/login/v2/getOtp', this.paramData);
    
  }

  login(mobile, otp){
    this.paramData = {
      mobile: mobile,
      otp: otp
    };
    // console.log(this.paramData);
    console.log('-----Login-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/login/v2/validateOtp', this.paramData);
  }

  thisCheckLogin(){
    let spareLoginToken = localStorage.getItem('spareLoginToken');
    if(!spareLoginToken){
      this.router.navigate(['login']);
    }else{
      this.router.navigate(['home']);
    }
  }

  checkLogin(){
    let spareLoginToken = localStorage.getItem('spareLoginToken');
    if(!spareLoginToken){
      this.router.navigate(['login']);
    }
  }

  logout(){
    // Spares
    localStorage.removeItem('spareLoginToken');
    localStorage.removeItem('spareUserKey');
    localStorage.removeItem('selectedMake');
    localStorage.removeItem('selectedModel');
    localStorage.removeItem('selectedYear');
    localStorage.removeItem('variant');
    localStorage.removeItem('variantKey');
    localStorage.removeItem('rowKey');
    localStorage.removeItem('aspSpareAdded');
    localStorage.removeItem('spareRefreshToken');
    localStorage.removeItem('garageKey');
    localStorage.removeItem('spareLoginNumber');
    localStorage.removeItem('spareSelectedGarageName');
    localStorage.removeItem('newMake');
    localStorage.removeItem('newModel');
    localStorage.removeItem('newYear');
    localStorage.removeItem('newVariant');
    localStorage.removeItem('newVariantKey');
    localStorage.removeItem('newRowKey');
    localStorage.removeItem('isShippingCharges');
    localStorage.removeItem('prepayUpiDiscount');
    localStorage.removeItem('selectedBrandImg');
    localStorage.removeItem('agentKey');
    localStorage.removeItem('rzp_device_id');
    localStorage.removeItem('rzp_checkout_anon_id');
    localStorage.removeItem('selectedBrand');
    localStorage.removeItem('userType');
    localStorage.removeItem('vehicleData');
    localStorage.removeItem('selectedSparesList');
    localStorage.removeItem('availableWalletBalance');

    sessionStorage.clear();
    // End Spares

    this.router.navigate(['login']);
  }

}
