<!-- <div class="overlay" [class.visible]="isVisible" (click)="closeSidebar(false)"></div> -->
<div class="overlay" [class.visible]="isVisible" (click)="closeSidebar(false)"></div>
<div class="sidebar" [class.visible]="isVisible" >
   <div class="order-container">
      <div>
      <header>
         <div class="back-button">
            <i (click)="closeSidebar(false)" class="material-icons" style="color: black;font-weight: bold; cursor: pointer;">arrow_back</i>
            <div class="title">Select Garages</div>
         </div>
         
          
      </header>
            <div style="padding: 0px 8px;">
               <input
                  type="text" class="SearchInput" placeholder="Search Garages..." [(ngModel)]='searchText' name="searchText" autocomplete="off" (keyup)="searchFilterType()" >
               <button (click)="clearSearch()" class="button">
                  <i class="material-icons" style="font-size: 16px;">clear</i>
               </button>
            </div>
         </div>
         <div id="switch-garage-form">
         <div *ngFor="let list of filteredGarage" style="display: grid;padding: 0px 10px;">
            
            <div 
            class="garage-name" 
            [ngClass]="{'selected-garage': garageKey === list.garageKey}" 
            (click)="selectGarage(list.garageKey, list.name, list.contactEmail, list.contactPhone)">
            {{list.name}}</div>
         </div>
     </div>
   </div>

</div>
