import { Component, OnInit, NgZone, ViewChild, ViewContainerRef, ComponentFactoryResolver, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AspRequestDetailsPopUpComponent } from '../../auto-spare-parts/asp-request-details-pop-up/asp-request-details-pop-up.component';
import { ActivatedRoute } from '@angular/router';
import { AspRequestDetailsService } from '../../auto-spare-parts/asp-request-details/asp-request-details.service';
import {MatListModule} from '@angular/material/list';
import { AspCustomerQuoteComponent } from '../asp-customer-quote/asp-customer-quote.component';
import { AspOrderDetailsComponent } from '../asp-order-details/asp-order-details.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Location } from '@angular/common';


@Component({
  selector: 'app-asp-request-details',
  templateUrl: './asp-request-details.component.html',
  styleUrls: ['./asp-request-details.component.scss'],
})
export class AspRequestDetailsComponent implements OnInit {
  activeTab: string = 'requests'; 
  selectAll: boolean = false; 
  items = [
    { name: 'Lower Arm - LH', selected: false, availability: '05 Jul 2024', price: 1000 },
    { name: 'Lower Arm - RH', selected: false, availability: '05 Jul 2024', price: 1200 }
  ];
  sidebarOpen: boolean = false; 
  requestDetails: any; 
  spareRequestItemList: any; 
  quotationreceived: any[] = []; 
  quotationapproved: any[] = []; 
  quoteApproved: boolean = false; 
  id: any;
  itemDetails: any;
  quoteReceivedPrice: number=0;
  quoteApprovedPrice: number=0;
  selectedItemsCount: number = 0;
  totalSelectedPrice: number = 0;
  bitModule: number;
  bidItems: any;
  quoteReceived: boolean;
  totalMRP: number = 0;
  Quotereceived: any;
  sidebarOpenQuote: boolean = false;
  customerQuotation: any[]=[];
  customerQuotationPrice: number=0;
  totalSelectedPriceQuote: number=0;
  selectedItemsCountQuote: number=0;
  selectAllQuote:boolean = false; 
  isPopupOpen = false;
  successIcon: any = '../../../../assets/icons/icons8-ok.gif';
  itemQuote: any;
  isPopupOpenEdit: boolean;
  selectedItems: any[];
  loading: boolean = false;
  orderList: any[] = [];
  filteredOrderList: any[]=[];
  orderDeliveryTimeFormatted: string;
  item: any;
  resData: any;
  quoteShipping: any;
  regno: any;
  @Input() quoteId: string | null = null;  
  @Input() requestId: number | null = null;  
  @Input() jobCard: number | null = null;  
  
  itemDetail: any;
  selectedItemsQuote: any[];
  dataid: any;
  isMobile: boolean;
  

  constructor(
    public dialog: MatDialog,
    private aspRequestDetailsService: AspRequestDetailsService,
    private zone: NgZone,
    private route: ActivatedRoute,
    private resolver: ComponentFactoryResolver,
    private location: Location,

    private breakpointObserver: BreakpointObserver
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); }

  ngOnInit(): void {
    if(this.requestId && this.requestId!==null){
      this.id = this.requestId;
    }else{ 
      this.route.queryParams.subscribe(params => {
     
      // this.id = params.get('id');
        this.id = params['id'];        
        this.regno = params['regNo'];      
      });
    }
    this.getList(); 
    // this.submitDataUpdate();
    this.getRequestDetails();
    
     
  }
  @ViewChild('dynamicContainer1', { read: ViewContainerRef }) container1!: ViewContainerRef;
  @ViewChild('dynamicContainer', { read: ViewContainerRef }) container!: ViewContainerRef;  
  loadorderQuote(id) {
    const factory = this.resolver.resolveComponentFactory(AspCustomerQuoteComponent);
    const componentRef = this.container1.createComponent(factory);

    // Access the instance and call the method
    const componentBInstance = componentRef.instance as AspCustomerQuoteComponent;
    componentBInstance.closeSidebar(true);
    componentBInstance.resId(id)
    this.closePopup()

  }


  loadorderdetails(orderKey) {
    
  const factory = this.resolver.resolveComponentFactory(AspOrderDetailsComponent);
  const componentRef = this.container.createComponent(factory);

  // Access the instance and call the method
  const componentBInstance = componentRef.instance as AspOrderDetailsComponent;
  componentBInstance.closeSidebar(true);
  componentBInstance.getOrderDetails(orderKey);

  }
  openPopup(): void {
    this.isPopupOpen = true;
    this.CloseSidebarQuote();
    this.submitData(); 
  }

  closePopup(): void {
    this.isPopupOpen = false;
  }
  
  getMakeImageUrl(make: string): string {
    if(make){
      const makeWithoutSpaces = make.toLowerCase().replace(/\s+/g, '');
      return `https://cube.getpitstop.com/assets/img/${makeWithoutSpaces}.png`;
    }else {
      return
    }
  }

  
  getRequestDetails() {
    const garageKey = localStorage.getItem('garageKey');
    const requestId = this.id;
    const userKey = localStorage.getItem('spareUserKey');

    this.aspRequestDetailsService.requestDetails(garageKey, requestId, userKey).subscribe(result => {
      const res = JSON.parse(JSON.stringify(result));
    // console.log("res",res)
      
      if (res.code === 200) {
        this.requestDetails = res.data;
        // console.log('Request Details:', this.requestDetails);

        
        this.requestDetails.spareRequestItemList.forEach(item => {

          res.data.bidDetails.forEach(bid => {
            // bid.estimateItem[item.itemName].forEach(bidItem => {
            let bidItem=bid.estimateItem[item.itemName][0];
              bidItem.itemName = item.itemName;
              bidItem.itemQuantity = item.itemQuantity;                                
              bidItem.itemStatus = item.status; 
              bidItem.itemId = item.id;
              bidItem.lineitemId = item.lineitemId;
              bidItem.spareQuotationId = item.spareQuotationId;  
                      

              if (bidItem.itemStatus === 8) {
                this.quotationreceived.push(bidItem);
                this.quoteReceivedPrice = this.quoteReceivedPrice + ((bidItem.mrp - (bidItem.mrp* bidItem.sellingDiscount /100))*bidItem.itemQuantity);                
              }

              if ([4, 5, 6, 7, 9].includes(bidItem.itemStatus)) {                             
                this.quotationapproved.push(bidItem);            
                this.quoteApprovedPrice = this.quoteApprovedPrice + ((bidItem.mrp - (bidItem.mrp* bidItem.sellingDiscount /100))*bidItem.itemQuantity);
              }
              if ([4, 5, 6, 7, 8, 9].includes(bidItem.itemStatus)) {                             
                this.customerQuotation.push(bidItem);            
                this.customerQuotationPrice = this.customerQuotationPrice + (bidItem.mrp *bidItem.itemQuantity);
              }

                      
          });
          
        });

        // console.log('quotationreceived: ', this.quotationreceived); 

        
        // if (this.quotationapproved.length > 0) {
        //   this.activeTab = 'quote-approved';
        //   this.quoteApproved = true;
        // } else if (this.quotationreceived.length > 0) {
        //   this.activeTab = 'quote-received';
        // } else {
        //   this.activeTab = 'quote-received';
        // }
      }
    });
  }


  isQuoteApproved(): boolean {
    return this.quotationapproved.length > 0;
  }

 
  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }


  toggleSelectAll(): void {
    this.selectAll = !this.selectAll;
    this.quotationreceived.forEach(item => item.selected = this.selectAll);
    this.updateSelectedItems();
  }
  toggleSelectAllQuote(): void {
    this.selectAllQuote = !this.selectAllQuote;
    this.customerQuotation.forEach(quote => quote.selected = this.selectAllQuote);
    this.updateSelectedItemsQuote();
  }


  toggleItemSelection(): void {
    this.selectAll = this.quotationreceived.every(item => item.selected);
    this.updateSelectedItems();
  }
  toggleItemSelectionQuote(): void {
    this.selectAllQuote = this.customerQuotation.every(quote => quote.selected);
    this.updateSelectedItemsQuote();
  }

  updateSelectedItems(): void {
    this.selectedItemsCount = this.quotationreceived.filter(item => item.selected).length;
    // this.totalSelectedPrice = this.totalSelectedPrice + ((bidItem.mrp - (bidItem.mrp* bidItem.sellingDiscount /100))*bidItem.itemQuantity);
    this.totalSelectedPrice = this.quotationreceived.filter(item => item.selected).reduce((total, item) => total+((item.mrp - (item.mrp* item.sellingDiscount /100))*item.itemQuantity), 0);
    // this.quoteApprovedPrice = this.quoteApprovedPrice + ((bidItem.mrp - (bidItem.mrp* bidItem.sellingDiscount /100))*bidItem.itemQuantity);
    
  }
  updateSelectedItemsQuote(): void {
    this.selectedItemsQuote = this.customerQuotation.filter(quote => quote.selected)
    this.selectedItemsCountQuote = this.customerQuotation.filter(quote => quote.selected).length;
    // this.totalSelectedPrice = this.totalSelectedPrice + ((bidItem.mrp - (bidItem.mrp* bidItem.sellingDiscount /100))*bidItem.itemQuantity);
    this.totalSelectedPriceQuote = this.customerQuotation.filter(quote => quote.selected).reduce((total, quote) => total+(quote.mrp *quote.itemQuantity), 0);
    // this.quoteApprovedPrice = this.quoteApprovedPrice + ((bidItem.mrp - (bidItem.mrp* bidItem.sellingDiscount /100))*bidItem.itemQuantity);
    
  }
 
  anyItemSelected() {
    return this.selectedItemsCount > 0;
  }
  anyItemSelectedQuote() {
    return this.selectedItemsCountQuote > 0;
  }

  
  // selectedItemsCount(): number {
  //   return this.items.filter(item => item.selected).length;
  // }

  
  totalAmount(): number {
    return this.items.filter(item => item.selected).reduce((total, item) => total + item.price, 0);
  }
  totalAmountQuote(): number {
    return this.items.filter(quote => quote.selected).reduce((total, item) => total + item.price, 0);
  }

  // totalApprovedAmount(): number {
  //   return this.quotationapproved.reduce((total, item) => total + item.mrp, 0);
  // }
  
  calculateDiscountedPrice(mrp: number, discount: number): number {
    return mrp - (mrp * discount / 100);
  }
  
  openSidebar(itemdata): void {
    // console.log('Item Data:', itemdata);
    this.itemDetails=[];
    this.itemDetails=itemdata;
    this.itemDetails.discountedPrice = this.calculateDiscountedPrice(itemdata.mrp, itemdata.discount);
    this.sidebarOpen = true;


    // this.itemDetails.brandName = itemdata.bidDetails?.[0]?.estimateItem?.Battery?.[0]?.brand;

    // this.sidebarOpen = true;
    

  }
  openEditDialog(itemQuotes){
    this.itemQuote=[];
    this.itemQuote=itemQuotes;
    this.itemQuote.discountedPrice = this.calculateDiscountedPrice(itemQuotes.mrp, itemQuotes.discount);
    this.isPopupOpenEdit = true;
    this.selectedItemsCountQuote = 0;
    this.customerQuotation.forEach(quote => {
      quote.selected = false;
    });
    this.customerQuotation.forEach(quote => quote.selected = this.selectAllQuote=false);
  }
  closePopupEdit(): void {
    this.isPopupOpenEdit = false;
  }
 
  closeSidebar(): void {
    this.sidebarOpen = false;
  }

 
  getAllRequestPopup = (id, approvedAt) => {
    this.zone.run(() => {
      this.dialog.open(AspRequestDetailsPopUpComponent,{
        "data":{
          "id":id,
          "approvedAt":approvedAt,
        }
       });
     });
   }

  calculateMRP(item: any): number {
    this.quotationapproved.forEach
    return (item.mrp - (item.mrp * item.sellingDiscount / 100)) * item.itemQuantity;
  }

  calculateTotalMRP(): void {
    this.totalMRP = this.items.reduce((total, item) => {
      return total + this.calculateMRP(item);
    }, 0);
  }

  approveQuote() {
    let spareRequestItems = [];
  
    // Collect selected items for approval
    this.quotationreceived.forEach(received => {
      if (received.selected === true) {
        let data = {};
        data['itemId'] = received.itemId;
        data['lineitemId'] = received.lineitemId;
        data['spareQuotationId'] = received.spareQuotationId;
        spareRequestItems.push(data);
      }
    });
  
    const garageKey = localStorage.getItem('garageKey');
    const userKey = localStorage.getItem('spareUserKey');
  
    // Approve the selected quotes
    this.aspRequestDetailsService.approveQuote(garageKey, spareRequestItems, userKey).subscribe(result => {
      const res = JSON.parse(JSON.stringify(result));
  
      if (res.code == 200) {
        // Show pop-up after approval
        this.getAllRequestPopup(res.data.id, res.data.approvedAt);
  
        // Automatically switch to the "quote-approved" tab after pop-up
        this.setActiveTab('quote-approved');
  
        // Reload the approved quotes
        this.reloadApprovedQuotes();
  
        // Remove approved items from the quote-received array
        this.quotationreceived = this.quotationreceived.filter(item => !item.selected);
  
        // Reset the selected count and total selected price
        this.selectedItemsCount = 0;
        this.totalSelectedPrice = 0;
      }
    });
  }
  
  // Function to reload approved quotes and avoid duplicates
  reloadApprovedQuotes() {
    const newApprovedItems = this.quotationreceived.filter(item => !this.quotationapproved.some(approved => approved.itemId === item.itemId));
    
    // Add only new items to the approved list
    this.quotationapproved.push(...newApprovedItems);
  
    // Recalculate total approved price
    this.quoteApprovedPrice = this.quotationapproved.reduce((total, item) => 
      total + ((item.mrp - (item.mrp * item.sellingDiscount / 100)) * item.itemQuantity), 0);
  }
  
  openSidebarQuote(): void {
    this.sidebarOpenQuote = true; 
    // this.submitDataUpdate();    
  }
  CloseSidebarQuote(): void {
    this.sidebarOpenQuote = false;     
  }

  save(): void {
    // Find the index of the current item in the customerQuotation array
    const index = this.customerQuotation.findIndex(i => i.name === this.itemQuote.name);

    // If the item exists, update it
    if (index ) {
        // Update the item in the list with the new unit price and recalculate total amount
        this.customerQuotation[index].mrp = this.itemQuote.mrp;
        this.customerQuotation[index].totalAmount = this.itemQuote.mrp * this.itemQuote.itemQuantity;
        }
          // Recalculate the total quotation price
    this.customerQuotationPrice = this.calculateTotalQuotationPrice();
  
    
    

    // Close the popup after saving
    this.closePopupEdit();

}
calculateTotalQuotationPrice(): number {
  // Recalculate total price by summing up all items' total amounts
  return this.customerQuotation.reduce((sum, item) => sum + (item.mrp * item.itemQuantity), 0);
}

submitData() {
  // Create a clean data object with only the required fields
  if (!this.quoteId) {
    this.selectedItems = this.customerQuotation.filter(quote => quote.selected)
    this.selectedItems.forEach(item => {
      this.item=item.bidId;
    });

    const cleanData = {
      
      bidId: this.item,
      garageKey: localStorage.getItem('garageKey'),
      items: this.selectedItems.map(item => ({
        id: item.itemId,
        part_name: item.itemName,
        partNumber: item.partNumber,
        sellingPrice: item.mrp
      })),
      labourItems:[],
      registrationNumber: this.regno,
      requestId: this.id,
      userKey:localStorage.getItem('spareUserKey'),
    };

    this.aspRequestDetailsService.submitData(cleanData).subscribe(response => {
      this.resData=response['data'];
      this.dataid=response['data'].id;
      console.error('response:',response );
    }, error => {
      console.error('Error occurred:', error);
    });
  }else{    
    this.submitDataUpdate();
  }
}

getList() {
  this.loading = true;
  let spareUserKey = localStorage.getItem('spareUserKey');
  let garageKey = localStorage.getItem('garageKey');
  this.aspRequestDetailsService.orderList(spareUserKey, garageKey).subscribe(
    result => {
      let res = JSON.parse(JSON.stringify(result)); 
              
      if(res.code === 200){ 
        this.loading = false;
        res.data.orders.data.forEach(item => {
          item.titleName = this.formatOrderDeliveryTime(item.orderConfirmTime, item.orderDeliveryTime);
        });
        this.orderList = res.data.orders.data;

        this.orderList.forEach(order => {
          if (this.id==order.requestId){
            this.filteredOrderList.push(order)
          }
          
        });
      } else {
        this.loading = false;
      }
    }
  );
}

formatOrderDeliveryTime(confirmTime: string, deliveryTime: string) {
  try {
    const confirmDate = new Date(confirmTime.replace(" ", "T"));
    const deliveryDate = new Date(deliveryTime.replace(" ", "T"));

    if (isNaN(confirmDate.getTime()) || isNaN(deliveryDate.getTime())) {
      return '0 minutes';
      
    }

    const diffMs = deliveryDate.getTime() - confirmDate.getTime();
    const diffMins = Math.floor(diffMs / 60000);

    if (diffMins < 0) {
      return '0 minutes';
      
    }

    if (diffMins <= 180) {
      return `${diffMins} minutes`;
    } else {
      const hours = Math.floor(diffMins / 60);
      const minutes = diffMins % 60;
      return `${hours}H ${minutes} min`;
    }

  } catch (error) {
    return '0 minutes';
  }
}
submitDataUpdate() {
  // if (this.quoteId) {
  
  // Create a clean data object with only the required fields
  this.selectedItems = this.customerQuotation.filter(quote => quote.selected)
  this.selectedItems.forEach(item => {
    this.item=item.bidId;
  });
  
  const cleanData1 = {
    
    bidId: this.item,
    garageKey: localStorage.getItem('garageKey'),
    items: this.selectedItems.map(item => ({
      id: item.itemId,
      part_name: item.itemName,
      partNumber: item.partNumber,
      sellingPrice: item.mrp
    })),
    quoteId: this.quoteId,
    registrationNumber: this.regno,
    requestId: this.id,
    userKey:localStorage.getItem('spareUserKey'),
  };
  
  this.aspRequestDetailsService.submitDataUpdate(cleanData1).subscribe(response => {
    this.resData=response['data']
    console.error('response11:',response );
  });
}
goBack(): void {
  this.location.back();
}
}

// }
