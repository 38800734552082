import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspMyJobCardsService {

  constructor(private http: HttpClient,) { }

  getMyJobCards(garageKey: string,bookingId,search){
    let paramData = {  
      garageKey: garageKey,
      bookingId: bookingId,
      search: search
  };

  return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/booking/v1/jobCard/get?page=0&size=200', paramData);

  }
}
