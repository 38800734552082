import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetWalletDetailsService {
  getOrderDetails: any;


  constructor(private http: HttpClient) { }

  // getWalletData() {
  //   const garageKey = localStorage.getItem('garageKey');
  //       const userKey = localStorage.getItem('spareUserKey');
  //   return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}`+ `/user/wallet/${garageKey}/${userKey}`);

  //   }

  getWalletData() {
    const garageKey = localStorage.getItem('garageKey');
    const userKey = localStorage.getItem('spareUserKey');
  
    return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}/user/wallet/${userKey}/${garageKey}`);
  }
  
  

    getAppData(source: any): Observable<any> {
      const param = {
        source: "web"
      };
      return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}`+ '/app/getAppData',param);
  }
}

