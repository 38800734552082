import { Component, ComponentFactoryResolver, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { AppComponent } from 'app/app.component';
// import { LoginService } from 'app/main/authentication/login/login.service';
import { AspLoginService } from 'app/main/auto-spare-parts/asp-login/asp-login.service';
import { DoorstepStatus, PickupStatus } from 'app/main/globals';
// import { SearchOrdersService } from 'app/main/orders/search-orders/search-orders.service';
import { navigation } from 'app/navigation/navigation';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {SearchSuggestionService} from 'app/shared/search-suggestion.service'
import { TranslateService } from '@ngx-translate/core';
import{GetWalletDetailsService} from '../../../../app/shared/get-wallet-details.service';
import { AspGarageSidebarComponent } from 'app/main/auto-spare-parts/asp-garage-sidebar/asp-garage-sidebar.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { replace } from 'lodash';


export interface SearchOrdersData {
    orderId: string;
    registration: string;
    fuelType: string;
    vehicle: string;
    orderStartTime: any;
    orderEndTime: any;
    grandTotal: string;
    status: string;
}

@Component({
    host: {
        '(document:click)': 'onClick($event)'
      },
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    isfullscreen: any;
    searchForm: FormGroup;
    loading = false;
    noData = false;
    submitted = false;
    completed = false;
    selectedValue = '';
    searchOrders: any;
    searchOrdersList: any;
    displayOrders: [];
    searchOrdersData: any = [];
    searchSource: MatTableDataSource<SearchOrdersData>;
    searchOrdersColumns: string[] = ['orderId', 'registration', 'orderStartTime', 'orderEndTime', 'grandTotal', 'status'];
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    userData: any = { name: '', img: '' };
    loginData: any;
    timezone: any;
    format: any;
    moment = moment();
    showSearch = false;
    marketPlaceAppKey: any;
    marketPlaceAccountId: any;
    permissionType: any;
    showCreateOrder = false;
    offset = 1;
    notificationData: any;
    responseData: any;
    totalNotification: any;
    cartCount = 0;
    showNotifaction = false;
    notificationId: any;
    deactivateResult: any;
    showPaginations = false;
    showNotification = false;
    showPrevButton = true;
    noNotification = false;
    isInputVisible = false;
    translateImg:any = './assets/icons/language-change-icon.png';
    langSwitchImg = [{"en":"./assets/icons/en.png", "hi": "https://cube.getpitstop.com/prodimages/pitstop_delhi_icon.png", "ka": "https://cube.getpitstop.com/prodimages/pitstop_bengaluru_icon.png"}];
    searchText = ''
    suggestions: string[] = [];
    sidebarOpen: boolean = false;
    totalAmount = 0;
    walletInfoData: any = {};
    isMobile: boolean = false;
    isDrawerVisible = false;
    knowMoreHeading: string = '';
    knowMorePoints: string[] = [];
    selectedGarage: string;

    
    
    // allSuggestions: string[] = ['Part Name', 'Part Number', 'Brand', 'Category'];
    selectedSuggestions: Set<string> = new Set<string>();
    
    // Private
    private _unsubscribeAll: Subject<any>;
    @ViewChild('TABLE', { static: false }) table: ElementRef;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    suggestionType: any;
    suggestionAll: any=[];
    @ViewChild('dropdown') dropdown;
    walletInfo: any = [];
    walletAmountToUse: number;
    availableWalletBalance: any;
    spareSelectedGarageName: any;
    knowMoreString: any;
    orderDeliverCredit: any;
    heading: string = '';
    points: string = '';
    
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        public router: Router,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        // public loginService: LoginService,
        public loginService: AspLoginService,
        private _formBuilder: FormBuilder,
        // private soService: SearchOrdersService,
        private appComponent: AppComponent,
        private _fuseNavigationService: FuseNavigationService,
        private _eref: ElementRef,
        public translate: TranslateService,
        public searchSuggestionService:SearchSuggestionService,
        private getwalletdetailsService: GetWalletDetailsService,
        private resolver: ComponentFactoryResolver,
        
        private breakpointObserver: BreakpointObserver
    ) {
        this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
        document.addEventListener('click', this.offClickHandler.bind(this));
        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon': 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon': 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon': 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];

        this.navigation = navigation;
        // this.timezone = this.loginService.getTimezone();
        // this.format = this.loginService.getFormat();
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.searchForm = this._formBuilder.group({
            condition: ['', [Validators.required]],
            query: ['', [Validators.required]]
        });

        if (window.innerWidth > 960) {
            this.showSearch = true;
        } else {
            this.showSearch = false;
        }

        // Register translation languages
        translate.addLangs(['en', 'hi']);
        // Set default language
        translate.setDefaultLang('en');

    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (window.innerWidth > 960) {
            this.showSearch = true;
        } else {
            this.showSearch = false;
        }
    }

    get f() { return this.searchForm.controls; }
    

    toggleSearch() {
        this.isInputVisible = !this.isInputVisible;
        if (this.isInputVisible) {
          setTimeout(() => {
            const input = document.querySelector('.SearchInput') as HTMLInputElement;
            if (input) {
              input.focus();
            }
          }, 0);
        }
      }
    
      clearSearch(event: Event) {
       event.stopPropagation();
        // this.searchText = ''; // Clear the search text
        this.isInputVisible = false; // Hide the input field if needed
        this.filterSuggestions();
      }
      @ViewChild('dynamicContainer', { read: ViewContainerRef }) container!: ViewContainerRef;
      
      loadorderdetails() {
    console.log('Loading order details for:', this.selectedGarage);
        const factory = this.resolver.resolveComponentFactory(AspGarageSidebarComponent);
        const componentRef = this.container.createComponent(factory);
    
        // Access the instance and call the method
        const componentBInstance = componentRef.instance as AspGarageSidebarComponent;
        componentBInstance.closeSidebar(true);
    
      }
      
    
    //   getHighlightedText(text: string, search: string): string {
    //     const parts = text.split(new RegExp(`(${search})`, 'gi'));
    //     return parts.map(part => part.toLowerCase() === search.toLowerCase()
    //       ? `<span class="highlight">${part}</span>`
    //       : part
    //     ).join('');
    //   }
    getHighlightedText(text: any, search: any): string {
        if (typeof text !== 'string' || typeof search !== 'string') {
            // Handle cases where text or search is not a string
            return text;
        }
    
        if (!search) {
            return text; // Return original text if no search term
        }
    
        const parts = text.split(new RegExp(`(${search})`, 'gi'));
        return parts.map(part => part.toLowerCase() === search.toLowerCase()
            ? `<span class="highlight">${part}</span>`
            : part
        ).join('');
    }
   

    
    filterSuggestions() {
        if (this.searchText && this.searchText.length >= 3) {
            let rowKey = localStorage.getItem('rowKey');
            
            let universal = false;
            let variantKey = localStorage.getItem('variantKey');
            
            this.searchSuggestionService.getSearchSuggestions(rowKey, this.searchText, universal, variantKey).subscribe(
                result => {

    
                    this.suggestions = [];
                    if (result['data'].partNumbers && result['data'].partNumbers.length > 0)
                        this.suggestions.push(...result['data'].partNumbers.map((value: string) => ({ value, type: 'partNumber' })));
                    if (result['data'].brands && result['data'].brands.length > 0)
                        this.suggestions.push(...result['data'].brands.map((value: string) => ({ value, type: 'brand' })));
                    if (result['data'].categories && result['data'].categories.length > 0)
                        this.suggestions.push(...result['data'].categories.map((value: string) => ({ value, type: 'category' })));
                    if (result['data'].partNames && result['data'].partNames.length > 0)
                        this.suggestions.push(...result['data'].partNames.map((value: string) => ({ value, type: 'partName' })));
    
    
                    // Sort suggestions based on relevance
                    this.suggestions = this.sortSuggestionsByRelevance(this.suggestions, this.searchText);
    
                }
            );
        } else {
            this.suggestions = [];
        }
    }
    
    selectSuggestion(suggestion: { value: string, type: string }) {
        this.selectedSuggestions.add(suggestion.value);
        this.searchText = '';
        this.filterSuggestions();
        this.isInputVisible = false;
        if (suggestion.type === 'partNumber') {
            this.clearLocalStorage();
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['product-details/part-number/' + suggestion.value]);
            });
        } else if (suggestion.type === 'category') {
            this.clearLocalStorage();
            localStorage.setItem('selectedSparesList', JSON.stringify({"name": suggestion.value, "type": "categorySpares", "imageUrl": ""}));
            
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/spare-parts-list']);
            });
        } else if (suggestion.type === 'brand') {
            this.clearLocalStorage();
            localStorage.setItem('selectedSparesList', JSON.stringify({"name": suggestion.value, "type": "brandSpares", "imageUrl": ""}));
            
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/spare-parts-list']);
            });
        }else {            
            localStorage.setItem("preSearchValue", suggestion.value);
            this.clearLocalStorage();
            localStorage.setItem('selectedSparesList', JSON.stringify({"name": suggestion.value, "type": "allSpares", "imageUrl": ""}));
            
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/spare-parts-list']);
            });
        }
    }
    


    isFirstSuggestion(suggestion: string, index: number): boolean {
        // Check if it's the first suggestion and if it matches preSearchValue
        let preSearchValue=localStorage.getItem('preSearchValue');
        return index === 0 && preSearchValue === suggestion['value'];
    }
    

// Method to sort suggestions by relevance
sortSuggestionsByRelevance(suggestions: string[], searchText: string): string[] {
    return suggestions?.sort((a, b) => {
        const relevanceA = this.getRelevance(a, searchText);
        const relevanceB = this.getRelevance(b, searchText);
        return relevanceB - relevanceA; // Higher relevance comes first
    });
}

// Method to calculate relevance based on how well the suggestion matches the search text
getRelevance(suggestion: string, searchText: string): number {
    if (suggestion?.toString().toLowerCase() === searchText?.toLowerCase()) {
        return 3; // Exact match
    } else if (suggestion?.toString().toLowerCase().startsWith(searchText?.toLowerCase())) {
        return 2; // Starts with match
    } else if (suggestion?.toString().toLowerCase().includes(searchText?.toLowerCase())) {
        return 1; // Contains match
    } else {
        return 0; // No match
    }
}


    onSubmit() {
        this.openSearch();
        if (this.searchForm.invalid) {
            return;
        }
        this.noData = false;
        this.completed = false;
        this.loading = true;
        this.submitted = true;
        // this.getSearchOrdersData(this.f.condition.value, this.f.query.value);
    }
    toggleNotification(){
        this.showNotifaction = ! this.showNotifaction ;
        //  this.deActivateNotification();
    }
    closeNotification(){
        this.showNotifaction = false;
    }
    openCreateOrder(){
        this.router.navigate(['orders/create-order']);
    }
    // deActivateNotification(){
    //     this.soService.postDeactivatedNOtify(this.notificationId).subscribe(result => {
    //         this.deactivateResult=result;
    //         if(this.deactivateResult.response.status === 'SUCCESS'){
    //         this.getNotificationData();
    //         }
    //     },
    //     error => {
    //         console.log(error);
    //     });
    // }
    confirmedOrderDetails(orderId){
        this.router.navigate(['orders/details/' + orderId]);
        this.showNotifaction = false;
    }
    onClick(event) {
        if (!this._eref.nativeElement.contains(event.target)){
            this.showNotifaction = false;

    }
    }

    offClickHandler(event:any) {
        // if (!this._eref.nativeElement.contains(event.target)) { // check click origin
        //     this.dropdown.nativeElement.style.display = "none";
        //     this.searchText = '';
            
        // }
    }


    // getNotificationData(){
    //    const noticationIdArray = [];
    //     this.soService.getNotifications(this.offset).subscribe(result => {
    //         this.responseData = result;
    //         if(this.responseData.response.status === 'SUCCESS'){
    //             this.notificationData = this.responseData.response.body.notifications;

    //             this.notificationData.forEach(element => {
    //                 noticationIdArray.push(element.id);
    //             });
    //             this.notificationId = noticationIdArray.join();
    //             this.totalNotification = this.responseData.response.body.notificationsCount;
    //                 if(this.notificationData.length > 9){
    //                     this.showPaginations = true;
    //                 }
    //                 else{
    //                     this.showPaginations = false;
    //                 }
    //                 if(this.totalNotification > 0){
    //                     this.showNotification = true;
    //                 }
    //                 else{
    //                     this.showNotification = false;
    //                 }
    //                 if(this.notificationData.length < 1 ){
    //                         this.noNotification=true;
    //                 }
    //                 else{
    //                     this.noNotification=false;
    //                 }

    //         }
    //         else{

    //         }
    //       },
    //       error => {
    //           console.log(error);

    //       });
    // }
    // prevNotify(){
    //     this.offset = this.offset - 1;
    //     if(this.offset < 2 ){
    //     this.showPrevButton = true;
    //     }
    //     else{
    //         this.showPrevButton = false;
    //     }
    //     this.soService.getNotifications(this.offset).subscribe(result => {
    //     this.responseData = result;
    //     this.notificationData = this.responseData.response.body.notifications;
    //     this.totalNotification = this.responseData.response.body.notificationsCount;
    //     this.noNotification=false;
    //   });
    // }
    // nextNotify(){
    //     this.showPrevButton=false;
    //     this.offset = this.offset + 1;
    //     this.soService.getNotifications(this.offset).subscribe(result => {
    //     this.responseData = result;
    //     if(this.responseData.response.status === 'SUCCESS'){
    //     this.notificationData = this.responseData.response.body.notifications;
    //     this.totalNotification = this.responseData.response.body.notificationsCount;
    //     if(this.notificationData.length < 1 ){
    //         this.noNotification=true;
    //     }
    //     else{
    //         this.noNotification=false;
    //     }
    //     }
    //   });
    // }
    // getSearchOrdersData(condition: string, query: string) {
    //     this.searchOrdersData = [];
    //     this.soService.getOrders(condition, query).subscribe((data: {}) => {
    //         this.searchOrdersList = data;
    //         if (this.searchOrdersList.response.status === 'SUCCESS') {
    //             this.searchOrders = this.searchOrdersList.response.body.orders;
    //             if (this.searchOrders.length === 0) {
    //                 this.searchSource = new MatTableDataSource(this.searchOrdersData);
    //                 this.searchSource.sort = this.sort;
    //                 this.searchSource.paginator = this.paginator;
    //                 this.noData = true;
    //                 this.completed = true;
    //                 this.loading = false;
    //             } else {
    //                 this.searchOrders.forEach(searchOrderList => {
    //                     this.searchOrdersData.push(this.createSearchOrdersData(searchOrderList));
    //                 });
    //                 this.searchSource = new MatTableDataSource(this.searchOrdersData);
    //                 this.searchSource.sort = this.sort;
    //                 this.searchSource.paginator = this.paginator;
    //                 this.loading = false;
    //                 this.completed = true;
    //             }
    //         } else {
    //             this.loading = false;
    //             this.appComponent.openSnackBar('Session Timeout', 'Redirecting...');
    //             setTimeout(() => {
    //                 this.loginService.logout();
    //             }, 1000);
    //         }
    //     });
    // }

    // createSearchOrdersData(data: any): SearchOrdersData {
    //     let reg = '';
    //     let delivery = '';
    //     let startTime = '';
    //     let status = '';
    //     if (data.orderType.toString() === 'doorstep') {
    //         status = DoorstepStatus[data.status - 1];
    //     } else {
    //         status = PickupStatus[data.status - 1];
    //     }
    //     if (data.registration) {
    //         reg = data.registration.toString();
    //     }
    //     if (data.orderEndTime) {
    //         delivery = moment.unix(data.orderEndTime / 1000).tz(this.timezone).format(this.format);
    //     }

    //     if (data.orderStartTime) {
    //         startTime = moment.unix(data.orderStartTime / 1000).tz(this.timezone).format(this.format);
    //     }

    //     return {
    //         orderId: data.orderId.toString(),
    //         registration: reg,
    //         fuelType: data.fuelType.toString(),
    //         vehicle: data.model.toString(),
    //         orderStartTime: startTime,
    //         orderEndTime: delivery,
    //         grandTotal: parseFloat(data.grandTotal).toFixed(2),
    //         status: status.toString()
    //     };
    // }

    openDetails(id: number) {
        this.router.navigate(['orders/details/', id]);
        this.closeSearchResults();
    }

    closeSearchResults() {
        this.searchOrdersData = [];
        this.searchSource = new MatTableDataSource(this.searchOrdersData);
        this.searchSource.sort = this.sort;
        this.searchSource.paginator = this.paginator;
        this.noData = false;
        this.completed = false;
        this.loading = false;
        this.submitted = false;
        this.searchForm.markAsPristine();
        this.searchForm.markAsUntouched();
        this.showSearch = true;
    }
    
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // this.getNotificationData();
        
        this.loading = true;

        this.getWalletInfo();
        console.log('ngOnInit called, fetching app info...');

        this. getAppInfo();
    
        // Listen for changes in garage key and update wallet info
        const garageKey = localStorage.getItem('garageKey');
        if (garageKey) {
            this.onGarageChange(garageKey);
        }
        
        this.loginService.checkLogin();
        this.spareSelectedGarageName = localStorage.getItem("spareSelectedGarageName");
        
        if(!localStorage.getItem("availableWalletBalance")){
            this.getWalletInfo();
        }else{
            this.availableWalletBalance = localStorage.getItem("availableWalletBalance");
        }
       

        this.transaction.expired = this.isExpired(this.transaction.expiresOn);
        
        

        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });
        // this.getUserData();

        this.getCart();
        
    }

    

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
    }

    logout() {
        this.loginService.logout();
    }

    // getUserData() {
    //     if (this.loginService.getToken()) {
    //         this.loginData = JSON.parse(this.loginService.getLoginData());
    //         this.userData = { name: this.loginData.agentName, img: this.loginData.agentPhoto };
    //         return this.userData;
    //     }
    // }

    openFullscreen() {
        // Trigger fullscreen
        const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
            mozRequestFullScreen(): Promise<void>;
            webkitRequestFullscreen(): Promise<void>;
            msRequestFullscreen(): Promise<void>;
        };

        if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
            docElmWithBrowsersFullScreenFunctions.requestFullscreen();
        } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
            docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
        } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
        } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
            docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
        }
        this.isfullscreen = true;
    }

    closeFullscreen() {
        const docWithBrowsersExitFunctions = document as Document & {
            mozCancelFullScreen(): Promise<void>;
            webkitExitFullscreen(): Promise<void>;
            msExitFullscreen(): Promise<void>;
        };
        if (docWithBrowsersExitFunctions.exitFullscreen) {
            docWithBrowsersExitFunctions.exitFullscreen();
        } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
            docWithBrowsersExitFunctions.mozCancelFullScreen();
        } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            docWithBrowsersExitFunctions.webkitExitFullscreen();
        } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
            docWithBrowsersExitFunctions.msExitFullscreen();
        }
        this.isfullscreen = false;
    }

    openProfile() {
        this.router.navigate(['profile']);
    }

    openSearch() {
        this.showSearch = !this.showSearch;
    }


    getCart(){
        if(sessionStorage.getItem('addedCart')){    
          let cartData; 
          this.cartCount = 0;          
          cartData = JSON.parse(sessionStorage.getItem('addedCart'));          
          Object.values(cartData[0]).forEach(cart => {             
            this.cartCount += cart['cart_qty'];            
          });       
        }

        
    }

    openCart(){
        // let cartCountNew = (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML;
        // if(Number(cartCountNew) > 0){
            this.router.navigate(['cart']);
        // }
    }

    //Switch language
    translateLanguageTo(lang: string) {
        this.translate.use(lang);
    }

    openSidebar(): void {        
        this.getWalletInfo();
        this.sidebarOpen = true;     
      }
    
      closeSidebar(): void {
        this.sidebarOpen = false;
        this.closeDrawer();
      }

      onGarageChange(newGarageKey: string): void {
        // Update the garage key in local storage
        // Reset balance
        this.availableWalletBalance = 0;
        localStorage.removeItem('availableWalletBalance');
        
        // Fetch new wallet info
        this.getWalletInfo();
       
    }
      getWalletInfo(): void {
        const garageKey = localStorage.getItem('garageKey');
        const userKey = localStorage.getItem('spareUserKey');

    
        this.getwalletdetailsService.getWalletData().subscribe(
          res => {
            if (res['code'] === 200) {
                this.walletInfoData =  res['data'];  

                
                this.availableWalletBalance = this.walletInfoData.totalAmount;
                localStorage.setItem("availableWalletBalance", this.availableWalletBalance);

                
                this.walletInfoData.transactions.sort((a, b) => {
                    return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
                });
            }
            // Handle the data as needed
          },
          (error) => {
            console.error('Error fetching wallet data:', error);
            // Handle the error
          }
        );
      }
    
      // Other methods...

      today: Date = new Date();

        isExpired(transactionExpiresOn: Date): boolean {
        return new Date(transactionExpiresOn) < this.today;
        }

        transaction = {
            subTitle: 'Paid To',
            title: 'Order Payment Done.',
            createdOn: new Date('2023-11-20T11:56:23'),
            expiresOn: new Date('2024-06-27T07:35:06'),
            expired: false
          };



    private clearLocalStorage(){
        sessionStorage.removeItem('sparePartsDetails');
        localStorage.removeItem('vehicleType');
        localStorage.removeItem('newMake');
        localStorage.removeItem('newModel');
        localStorage.removeItem('newYear');
        localStorage.removeItem('newVariant');
        localStorage.removeItem('newVariantKey');
        localStorage.removeItem('newRowKey');
        localStorage.removeItem('isShippingCharges');
        localStorage.removeItem('prepayUpiDiscount');
        // localStorage.removeItem('vehicleData');
        localStorage.removeItem('selectedModel');
        localStorage.removeItem('selectedYear');
        localStorage.removeItem('variant');
        localStorage.removeItem('selectedFuel');
        localStorage.removeItem('variantKey');
        localStorage.removeItem('rowKey');
        localStorage.removeItem('selectedSparesList');
    };
        
    redeemNow() {
        window.open('https://www.xoxoday.com', '_blank');
    }
    openDrawer() {
        this.isDrawerVisible = true;
      }
    
      // Method to close the bottom sheet
      closeDrawer() {
        this.isDrawerVisible = false;
      }
      getAppInfo(): void {
        const source = "web"; 
    
        this.getwalletdetailsService.getAppData(source).subscribe(
            res => {
                const walletCredit = res.data.orderDeliverCredit.walletCredit;
                const minimumCartValue = res.data.orderDeliverCredit.minimumCartValue;
                const validity = res.data.orderDeliverCredit.validity;
      
            console.log("res", res);
                    let knowMore = res.data.orderDeliverCredit.knowMore;


                    knowMore = knowMore
                    .replace('{WALLETCREDITAMOUNT}', walletCredit)
                    .replace('{ORDERVALUE}', minimumCartValue)
                    .replace('{TIME}', `${validity} days`);


                    knowMore = knowMore.replace(/"points":\s*"\[(.*?)\]"/, (match, p1) => {
                        const fixedPoints = p1.replace(/\\\"/g, '"');
                        return `"points": [${fixedPoints}]`;
                    });


                    let test = JSON.parse(knowMore);

                    this.knowMoreHeading = test.heading;
                    this.knowMorePoints = test.points;
                    // this.knowMoreHeading = test.heading||'No heading available'

                    // this.knowMorePoints = test.points.map((point: string) =>
                    //     point.replace('{TIME}', validity)
                    //  );

                    const heading = test.heading;
                    const points = test.points;
                    this.heading = heading;
                    this.points = points;
              
              
                    // // Extract the heading
                    // this.heading = knowMore.heading;
              
                    // // Extract and parse the points
                    // const pointsString = knowMore.points;
              
                    // // Check if pointsString is defined
                    // if (!pointsString) {
                    //   throw new Error("pointsString is undefined");
                    // }
              
                    // // Parse the points directly since it is already in correct JSON array format
                    // this.points = JSON.parse(pointsString);
              
                    // // Log the extracted values for debugging
                    // console.log("Heading:", this.heading);
                    // console.log("Points:", this.points);
                  
                
            
                
                
                // if (res['code'] === 200) {
                //     const appData = res['data'];
                //     console.log('Received app data:', appData); // Log received app data
    
                //     // Access orderDeliverCredit object from appData
                //     const orderDeliverCredit = appData.orderDeliverCredit;
                //     if (orderDeliverCredit) {
                //         console.log('Order Deliver Credit:', orderDeliverCredit); // Log orderDeliverCredit object
    
                //         // Extract knowMore string
                //         this.knowMoreString = orderDeliverCredit.knowMore;
                //         console.log('Know More String:', this.knowMoreString); // Log the knowMore string
    
                //         // Parse the knowMore JSON string
                //         let knowMoreData;
                //         try {
                //             knowMoreData = JSON.parse(this.knowMoreString); // Parse the JSON string
                //             console.log('Parsed Know More Data:', knowMoreData); // Log the parsed data
                //         } catch (error) {
                //             console.error('Error parsing knowMoreString:', error);
                //             return; // Exit if parsing fails
                //         }
                //         this.knowMoreHeading = knowMoreData.heading;
                //         // Ensure knowMoreData is defined and access properties
                //         if (knowMoreData) {
                //             // Assign heading
                //             console.log('Know More Heading:', this.knowMoreHeading); // Log the heading
    
                //             // Access and parse points
                //             try {
                //                 const pointsArray = knowMoreData.points ? JSON.parse(knowMoreData.points) : [];
                //                 console.log('Know More Points:', pointsArray); // Log the points array
                //             } catch (error) {
                //                 console.error('Error parsing points array:', error);
                //             }
                //         } else {
                //             console.error('knowMoreData is undefined');
                //         }
                //     } else {
                //         console.error('No orderDeliverCredit found in appData');
                //     }
                // } else {
                //     console.error('Error code in response:', res['code']);
                // }
            },
            (error) => {
                console.error('Error fetching app data:', error);
            }
        );
    }
    setSelectedGarage(garageName: string) {
        this.selectedGarage = garageName;
      }
    
}    