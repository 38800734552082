import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspBrandsService {
  paramData: any;
  constructor(
    private http: HttpClient,
  ) { }

    
      allBrands(deviceId:string ,source:string,versionCode:number){
    this.paramData = {
      authKey: GlobalVariable.AUTH_KEY,
      deviceId:deviceId,
      source:source,
      versionCode: versionCode
      
    };

    // console.log(this.paramData);
    console.log('-----All Brands-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/spare/brands/all', this.paramData);
  }

}

