<div *ngIf="cartCount>0" class="page-layout blank fullwidth p-12" fusePerfectScrollbar>
    
    
    <!-- <mat-progress-bar class="mt-8" *ngIf="loading" mode="indeterminate"></mat-progress-bar> -->
    <div class="cart mt-8 p-8">            
        <h2 class="cart-title">My Cart</h2>
        <div class="cart-left">
            <div *ngIf="makeImage && model && year && variant" class="vehicle-details-sec cart-card">
                <div class="make-sec">
                    <img class="img-fluid make-image" src="{{makeImage}}" />
                </div>
                <div class="model-sec">
                    {{model}},
                </div>
                <div class="year-sec">
                    {{year}},
                </div>
                <div class="variant-sec">
                    {{variant}}
                </div> 
            </div>

            <div *ngIf="vehicleType=='universal' && !make" class="vehicle-details-sec cart-card">
                <div class="universal-make-sec">
                    <mat-icon style="font-size: 35px;"> directions_car</mat-icon>
                </div>
                <div class="model-sec universal-sec">Universal</div>                              
            </div>
            <!-- <div class="location-sec">
                <div class="cart-card"> 
                    <div class="location-icon"><mat-icon> location_on</mat-icon></div>
                    <div class="location-content">
                        <div class="location-title">Location</div>
                        <div class="location-details">
                            {{shippingAddress}}
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="spares-added-sec cart-card">
                <div class="spares-added-title" >
                    <div class="spares-count">{{cartCount}}</div>
                    <h4>Spares Added</h4>
                </div>
                <div class="add-more-sec">
                    <button mat-button (click)="addMore()">Add more</button>
                </div>
            </div>  

            <div class="spares-added" *ngFor="let cart of cartList">
                <div class="cart-card mt-8 cart-instock"> 
                    <div class="spares-part"> 
                        <img *ngIf="cart.imageUrl" class="img-fluid part-image" src="{{cart.imageUrl}}" />
                        <img *ngIf="!cart.imageUrl" class="img-fluid part-image" src="../../../../assets/images/logos/logo.png" />
                    </div>
                    <div class="spare-details-sec">
                        <div class="spare-brand">{{cart.brand.name}}</div>
                        <div class="spare-part-name">{{cart.name}}</div>
                        <div class="spare-price" *ngIf="cart.discount>0; else onlyMRP">
                            <span class="selling-price">&#8377;{{cart.sellingPrice | number:'1.2-2'}}</span>
                            <span class="mrp-price">&#8377;{{cart.mrp | number:'1.2-2'}}</span>
                            <span class="discount-price">(You save &#8377;{{cart.discount | number:'1.2-2'}})</span>
                        </div>
                        <ng-template #onlyMRP>
                            <div class="spare-price-mrp">
                                &#8377;{{cart.mrp}}
                            </div>
                        </ng-template>
                    </div>
                    <!-- <div class="spare-add-cart" (click)="removeItem(cart.name)">
                        <mat-icon>delete_sweep</mat-icon>
                    </div>  -->
                    <div class="spare-add-cart" >
                        <div class="btn-group">
                            <button mat-raised-button (click)="removeItem(cart.cart_qty, cart.key)"><mat-icon>remove</mat-icon></button> 
                            <input type="text" class="part-count" [(ngModel)]="cart.cart_qty" readonly>
                            <button mat-raised-button (click)="addItem(cart.cart_qty, cart.key)"><mat-icon>add</mat-icon></button> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cart-right">
            <div class="discount-sec" *ngIf="sparesDiscount>0">
                <p>You have saved - &#8377;{{sparesDiscount | number:'1.2-2'}} on this order</p>
            </div>
            <div #target  class="price-details-sec" [@shakeit]="this.states['shake']" (@shakeit.done)="shakeEnd('shake', $event)">
                <div class="cart-card"> 
                    <div class="price-details-title">Price Details</div>

                    <div class="price-details">
                        <div class="price-details-left">Spares Total</div>
                        <div class="price-details-right">&#8377;{{cartSubTotal | number:'1.2-2'}}</div>
                    </div>
                    <!-- <div class="price-details" *ngIf="cartSubTotal>1999">
                        <div class="price-details-left">Shipping Charges
                            <i class="maticon" mat-raised-button matTooltip="Provides free Shipping for orders above ₹1999" (click)="openBottomSheet()">
                                info_outline
                              </i>
                        </div>
                        <div style="text-decoration: line-through;display: flex;left: 114px;position: relative;">₹60
                            <span class="price-details-right"  style="color: #4caf50;margin-left: -32px;">Free</span>
                        </div>
                    </div> 
                    <div class="price-details" *ngIf="cartSubTotal <= 1999">
                        <div class="price-details-left">Shipping Charges
                            <i class="maticon" mat-raised-button matTooltip="Provides free Shipping for orders above ₹1999" (click)="openBottomSheet()">
                                info_outline
                              </i>
                        </div>
                        
                            <span class="price-details-right"  >₹60</span>
                    </div> 
                    <ng-template #bottomSheetTemplate >
                        <div class="bottom-sheet-content">
                            <i class="material-icons" (click)="closeBottomSheet()" style="float: left;">cancel</i>
                          <h3 style="font-weight: bold;">Shipping Charge</h3>
                          <p style="font-size: 13px;">Autoverse provides free Shipping for orders above ₹1999</p>
                          <button mat-button class="custom-button" (click)="closeBottomSheet()">Okay, Got it</button>
                        </div>
                      </ng-template> -->

                    <!-- Spares Discount -->
                    <div class="price-details" *ngIf="sparesDiscount>0">
                        <div class="price-details-left">Spares Discount</div>
                        <div class="price-details-right" id="discount">- &#8377;{{sparesDiscount | number:'1.2-2'}}</div>
                    </div>     
                    
                    <!-- Coupon Discount -->
                    <!-- <div class="price-details" >
                        <div class="price-details-left">Coupon Discount</div>
                        <div class="price-details-right" id="coupon-discount" (click)="couponDiscountPopup()">Apply Coupon</div>
                    </div>   -->
                                
                    <!-- Shipping Charges -->
                    <div *ngIf="isShippingCharges=='true'" class="price-details" id="shipping-charges">
                        <div class="price-details-left">Shipping Charges <mat-icon (click)="shippingChargesPopup()">info</mat-icon></div>
                        <div *ngIf="shippingChargesApplicable==true" class="price-details-right">&#8377;{{shippingCharges | number:'1.2-2'}}</div>
                        <div *ngIf="shippingChargesApplicable==false" class="price-details-right">
                            <span class="mrp-price">&#8377;{{shippingCharges | number:'1.2-2'}}</span>
                            <span id="free-shipping">FREE</span>
                        </div>
                    </div>    

                    <div class="price-details" id="total">
                        <div class="price-details-left">Total</div>
                        <div class="price-details-right">&#8377;{{cartGrandTotal | number:'1.2-2'}}</div>
                    </div>
                </div>
            </div>
        </div>  

    </div>
    <div class="place-order-sec" *ngIf="cartList.length>0">
        <div class="grand-total">&#8377;{{cartGrandTotal | number:'1.2-2'}}</div>
        <div class="view-details">
            <a (click)="scroll(target)">View Details</a>
        </div>
        <button class="place-order-btn" (click)="payment()">Place Order</button>
    </div>
</div>

<!-- Empty Cart -->
<div *ngIf="cartCount==0" class="page-layout blank fullwidth p-12" fusePerfectScrollbar>
    <div class="empty-cart">
        <img src="{{emptyImg}}" />
        <div class="sec1">No spares added yet</div>
        <div class="sec2">Your car parts are just a click away</div>
        <button mat-button [ngClass]="isMobile ? 'browse-parts-btnMobile' : 'browse-parts-btn'" (click)="browseParts()">Browse Parts</button>
    </div>
</div><div *ngIf="loading">
    <div #dynamicContainers></div>
    </div>