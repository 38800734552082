<div class="container">

  <!-- JobCard Info -->
  <div class="header" *ngIf="requestId">
    <i class="material-icons" style="color: black; float: left; cursor: pointer;" (click)="goBack()" >keyboard_backspace</i> 
    <span class="title">Job Card Details</span>
    <div class="right">
      <!-- Placeholder for Request ID -->
      <span class="request-number">{{ jobCard.id}}</span>
    </div>
  </div>

  <div class="details" *ngIf="requestId">
    <div class="logo-and-model">
      <img [src]="getMakeImageUrl(requestDetails?.spareRequest?.make)" alt="Icon" class="img">
      <div class="model-info">
        <span class="model-name">{{requestDetails?.spareRequest?.make}}</span>
        <span class="model-details">{{requestDetails?.spareRequest?.model}}, {{requestDetails?.spareRequest?.fuelType}}</span>
      </div>
    </div>
    <div class="timestamp">{{requestDetails?.spareRequest?.created | date:'dd MMM yyyy, hh:mm a': 'GMT+1060'}}      
        <button type="button" class="QuoteBtn"  (click)="openSidebarQuote()" *ngIf="activeTab === 'quote-received' && (quotationreceived.length > 0 || quotationapproved.length > 0)" >Create Customer Quote</button>
     </div>
    
  </div>

  <!-- Information Section -->
  <div class="second-div-details" *ngIf="requestId">
    <div class="line">
      <div class="strong">Name</div>
      <div class="light">{{ jobCard.name || 'N/A' }}</div>
    </div>
    <div class="line">
      <div class="strong">Mobile Number</div>
      <div class="light">{{ jobCard.phoneNumber || 'N/A' }}</div>
    </div>
    <div class="line">
      <div class="strong">Odometer</div>
      <div class="light">{{ jobCard.odometer || 'N/A' }} km</div>
    </div>
    <div class="line">
      <div class="strong">Registration Number</div>
      <div class="light">{{ jobCard.registrationNumber || 'N/A' }}</div>
    </div>
    <div class="line">
      <div class="strong">Request ID</div>
      <div class="light">{{ jobCard.requestId || 'N/A' }}</div>
    </div>
  </div>
  <!-- End JobCard Info -->

  <div class="header" *ngIf="!requestId">
    <i class="material-icons" style="color: black; float: left; cursor: pointer;" (click)="goBack()" >keyboard_backspace</i> 
    <span class="title">Request Details</span>
    <div class="right">
      {{requestDetails?.spareRequest?.id}}
      <span class="request-number"></span>
    </div>
  </div>

  <div class="details" *ngIf="!requestId">
    <div class="logo-and-model">
      <img [src]="getMakeImageUrl(requestDetails?.spareRequest?.make)" alt="Icon" class="img">
      <div class="model-info">
        <span class="model-name">{{requestDetails?.spareRequest?.make}}</span>
        <span class="model-details">{{requestDetails?.spareRequest?.model}}, {{requestDetails?.spareRequest?.fuelType}}</span>
      </div>
    </div>
    <div class="timestamp">{{requestDetails?.spareRequest?.created | date:'dd MMM yyyy, hh:mm a': 'GMT+1060'}}
      
        <button type="button" class="QuoteBtn"  (click)="openSidebarQuote()" *ngIf="activeTab === 'quote-received' && (quotationreceived.length > 0 || quotationapproved.length > 0)" >Create Customer Quote</button>
      </div>
    
  </div>
  <mat-sidenav-container>
    <div class="overlay" [class.visible]="sidebarOpenQuote" (click)="CloseSidebarQuote()" ></div>
    <div class="sidebar" [ngClass]="{
      'sidebar-openQuote': sidebarOpenQuote && !isMobile,
      'sidebar-openQuoteMobile': sidebarOpenQuote && isMobile
    }">
      <mat-list>
      <header>
        <div class="back-button">
           <i (click)="CloseSidebarQuote()" class="material-icons-arrowback">arrow_back</i>
           <span class="title1">Customer Quotation</span>
           <div *ngIf="quoteId">
            <h2>{{ quoteId }}</h2>
          </div>
        </div>
     </header>
     <div class="detailsQuote">
      <div class="logoModel">
        <img [src]="getMakeImageUrl(requestDetails?.spareRequest?.make)" alt="Icon" class="img">
        <div class="model-info">
          <span class="model-name">{{requestDetails?.spareRequest?.make}}</span>
          <span class="model-details">{{requestDetails?.spareRequest?.model}}, {{requestDetails?.spareRequest?.fuelType}}</span>
        </div>
    </div>
      <mat-divider></mat-divider>

      <div  class="items" style="margin-bottom: 88px;">
        <div class="request-info">Quote received for {{requestDetails?.spareRequestItemList?.length}} items </div>
        <div class="item" >
          <div class="checkboxselect">
            <input type="checkbox" [checked]="selectAllQuote" (change)="toggleSelectAllQuote()">
            <span>Select All</span>
          </div>
          <div>
            <span class="customer-quote-price">₹ {{customerQuotationPrice | number:'1.2-2'}}</span>
            <div class="quote_total_amount"> Total Amount  </div>
          </div>
        </div>

        <div class="quotereceived-items" *ngFor="let quote of customerQuotation" [ngClass]="{'border-box': quote.selected}">
          <div class="checkboxselect">
            <div class="lines" >
              <input type="checkbox" [(ngModel)]="quote.selected" (change)="toggleItemSelectionQuote()">
              <span class="part-name">{{quote.itemName}}</span>
              <!-- <span class="ellipsis" >⋮</span> -->
              <i class="material-icons" (click)="openEditDialog(quote)">edit</i>

              <div class="overlay1" [class.visible]="isPopupOpenEdit" (click)="closePopupEdit()" ></div>
              <div *ngIf="isPopupOpenEdit" [ngClass]="isMobile ? 'bottom-sheetMobile' : 'bottom-sheet'">
              
                  <header class="back-button">
                  <h3 class="header-name">{{ itemQuote.itemName }}</h3>
                    <mat-icon (click)="closePopupEdit()" class="close-pop-up">close</mat-icon>
                  </header>
                  <div class="bottom-sheet-content">
                  <div>
                    <label>Brand</label>
                    <span >{{ itemQuote?.brand }}</span>
                  </div>
                  <div>
                    <label>Quantity</label>
                    <span>{{ itemQuote?.itemQuantity }}</span>
                  </div>
                  <div>
                    <label>Availability</label>
                    <span>{{ itemQuote?.edt  | date:'dd MMM yyyy, hh:mm a' }}</span>
                  </div>
                  <div>
                  
                    <label class="unit-prz">Unit Price</label>
                    <!-- <span class="inputPrice"> -->
                      <mat-form-field style="float: left;width: 20%;">
                    <input matInput  [(ngModel)]="itemQuote.mrp" />
                    
                  </mat-form-field>
                  <!-- </span> -->
                </div>
                  <div>
                    <label>Total Amount</label>
                    <span>₹ {{ itemQuote?.mrp * itemQuote?.itemQuantity  | number:'1.2-2'}}</span>
                  </div>
                  <button  (click)="save()">Save Quote</button>
                </div>
              </div>
              
              
            </div>
            <!-- <input type="checkbox" [(ngModel)]="item.selected" (change)="toggleItemSelection()"> -->
            <!-- <span>{{item.itemName}}</span> -->
            <div class="lines" >
              <span class="availability"> Availability: </span>
              <span class="date">₹{{quote.mrp*quote.itemQuantity | number:'1.2-2'}}</span>

            </div>
          </div>
          <div class="lines">
            <span class="date-time">{{quote.edt  | date:'dd MMM yyyy'}}</span>
            <!-- <span class="quantity">  </span> -->
          </div>
        </div>
      </div>
    </div>
    
    <div *ngIf="anyItemSelectedQuote()" [ngClass]="isMobile ? 'bottom-boxQuoteMobile':'bottom-boxQuote'">
      <div class="bottom-contentQuote">
        
          <div class="total-items">{{selectedItemsCountQuote}} items
          <p class="total-amount">₹{{totalSelectedPriceQuote | number:'1.2-2'}}</p>
        </div>
          <button type="button" class="createQuote" (click)="openPopup()">Create Customer Quote</button>
          
           
      </div>
    </div>
    
    </mat-list>
    
    
    
    </div>
     <!-- Popup content -->
    
    <div *ngIf="isPopupOpen" class="popup">
      <div class="popup-content">
      <img src="{{successIcon}}" style="width: 36%;">
      <h3 *ngIf="quoteId === null || quoteId === 'null'">Quote Created</h3>
      <h3 *ngIf="quoteId !== null && quoteId !== 'null'">Quote Updated</h3>
      <p class="order-label" *ngIf="quoteId === null || quoteId === 'null'">
        <span class="order-id">Quotation id {{ resData?.id }}</span>
      </p>
      <p class="order-label" *ngIf="quoteId !== null && quoteId !== 'null'">
        <span class="order-id">Quotation id {{ quoteId }}</span>
      </p>
      <p class="date"><span>{{resData?.placedOn | date:'dd-MMM-yyyy, hh:mm a'}}</span></p>
      <p class="thank you">You have successfully created quotation</p>
    
      <button class="goto-order" *ngIf="quoteId === null || quoteId === 'null'" (click)="loadorderQuote(resData.id)">View Customer Quote</button>
      <button class="goto-order" *ngIf="quoteId !== null && quoteId !== 'null'" (click)="loadorderQuote(quoteId)">View Customer Quote</button>
    </div>
  </div>
  
</mat-sidenav-container>

  <div class="third-div">
    <div class="buttons">
      <button class="request-button" 
              [ngClass]="{'active': activeTab === 'requests'}"
              (click)="setActiveTab('requests')">REQUESTS</button>
      <button class="request-button" 
              [ngClass]="{'active': activeTab === 'quote-received'}"
              (click)="setActiveTab('quote-received')">QUOTE RECEIVED </button>
      <button class="request-button" 
              [ngClass]="{'active': activeTab === 'quote-approved'}"
              (click)="setActiveTab('quote-approved')">QUOTE APPROVED</button>
      <button class="request-button" 
              *ngIf="filteredOrderList?.length>0"
              [ngClass]="{'active': activeTab === 'order-created'}"
              (click)="setActiveTab('order-created')">ORDER CREATED</button>
    </div>

    <!-- <div style="font-weight: 900;font-size: larger; padding: 10px 0;border-bottom: 1px solid #ddd;" class="request-info" *ngIf="activeTab !== 'quote-received' ">
      <strong>Request made for {{requestDetails.spareRequestItemList.length}} items </strong>
       <hr /> 
    </div> -->

    <div *ngIf="activeTab === 'requests'" class="request-item">
      <div class="request-info">Request made for {{requestDetails?.spareRequestItemList?.length}} items </div>
      <div class="item" *ngFor="let item of requestDetails?.spareRequestItemList">
        <span class="span-item-name">{{item.itemName}}</span>
        <span class="quantity">{{item.itemQuantity}} Unit</span>
      </div>
    </div>
    <!-- <hr /> -->

    <!-- Content for Quote Received Tab -->
    <div *ngIf="activeTab === 'quote-received'">
      
      <div *ngIf="quotationreceived.length > 0; else noQuotesReceived" class="items" style="margin-bottom: 88px;">
        <div class="request-info">Quote received for {{quotationreceived.length}} items </div>
        <div class="item" >
          <div class="checkboxselect">
            <input type="checkbox" [checked]="selectAll" (change)="toggleSelectAll()">
            <span >Select All</span>
          </div>
          <div>
            <span class="quote-rcvd-prz">₹ {{quoteReceivedPrice | number:'1.2-2'}}</span>
            <div class="total-slng-prz"> Total Selling Price  </div>
          </div>
        </div>
        <div class="quotereceived-items" *ngFor="let item of quotationreceived" [ngClass]="{'border-box': item.selected}">
          <div class="checkboxselect">
            <div class="lines" >
              <input type="checkbox" [(ngModel)]="item.selected" (change)="toggleItemSelection()">
              <span class="part-name" >{{item.itemName}}</span>
              <span class="ellipsis" (click)="openSidebar(item)">⋮</span>
            </div>
            <!-- <input type="checkbox" [(ngModel)]="item.selected" (change)="toggleItemSelection()"> -->
            <!-- <span>{{item.itemName}}</span> -->
            <div class="lines" >
              <span class="availability"> Availability: </span>
              <span class="date">₹{{(item.mrp - (item.mrp* item.sellingDiscount /100))*item.itemQuantity | number:'1.2-2'}}</span>

            </div>
          </div>
          <div class="lines">
            <span class="date-time">{{item.edt  | date:'dd MMM yyyy'}}</span>
            <!-- <span class="quantity">  </span> -->
          </div>
        </div>
      </div>

      <!-- Template for No Quotes Received -->
      <ng-template #noQuotesReceived >
        <div>
          <div class="empty-state">
            <div  *ngIf="isQuoteApproved(); else notApproved">
              <img src="../../../../assets/images/request-details images/requestdetailsimages.png" alt="Quote Approved" class="quote-image">
              <div  class="message">
                <strong >Quote Has Been Approved!</strong>
                <p>Check Quote Approved Section</p>
              </div>
            </div>
          </div>
          <ng-template #notApproved>
            <div class="empty-state"  *ngIf="quotationreceived.length === 0">
              <img src="../../../../assets/images/request-details images/requestdetailsimage.png" alt="Quote Not Created" class="quote-image">
              <div class="message">
                <strong>Quote Not Created Yet!</strong>
                <p>Stay tuned for updates</p>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </div>

    <!-- Content for Quote Approved Tab -->
    <div *ngIf="activeTab === 'quote-approved'">
      <div *ngIf="quotationapproved.length > 0; else noQuotesApproved" class="items">
        <div class="request-info"> Quote approved  for {{quotationapproved.length}} items </div>
        <div class="total-selling-price">
          <span>Total Selling Price</span>
          <span class="total-price">₹ {{quoteApprovedPrice | number:'1.2-2'}} </span>
        </div>
        <!-- <hr class="divider"> -->
        <div class="quoteaproved-items" *ngFor="let item of quotationapproved" [ngClass]="{'border-box': item.selected}">
          <div class="line" >
            <span class="part-name">{{item.itemName}}</span>
            <span class="ellipsis" (click)="openSidebar(item)">⋮</span>
          </div>
          <div class="line">
            <span class="availability">Availability</span>
            <span class="date">₹{{(item.mrp - (item.mrp* item.sellingDiscount /100))*item.itemQuantity}}</span>
          </div>
          <div class="line">
            <span class="date-time">{{item.edt  | date:'dd MMM yyyy'}}</span>
            <span class="amount">Total Selling Price</span>
        </div> 
      </div>  
    </div>
    <!-- Template for No Quotes Approved -->
    <ng-template #noQuotesApproved>
      <div class="empty-state">
        <div >
          
          <img src="../../../../assets/images/request-details images/requestdetailsimage.png" alt="Quote Not Approved" class="quote-image">
          <div class="message">
            <strong>Quote Not Approved Yet!</strong>
            <p>Awaiting your approval</p>
          </div>
        </div>
      </div>
    </ng-template>
  </div>

  <!-- Sidebar Section -->
  <div class="overlay" [class.visible]="sidebarOpen" (click)="closeSidebar()"></div>
   <div *ngIf='itemDetails' [class.sidebar-open]="sidebarOpen" class="sidebar">
          <div class="sidebar-header" >
            <span class="sidebar-title">{{itemDetails.itemName}}</span>
            <span class="close-btn" (click)="closeSidebar()">&times;</span>
          </div>
          <div class="sidebar-content">
            <div class="sidebar-item">
              <span class="label">Brand</span>
              <span class="value" >{{ itemDetails.brand}}</span>
            </div>
            <div class="sidebar-item">
              <span class="label">Quantity</span>
              <span class="value">{{itemDetails.itemQuantity}}</span>
            </div>
            <div class="sidebar-item">
              <span class="label">Availability</span>
              <span class="value">{{itemDetails.edt  | date:'dd MMM yyyy, hh:mm a'}}</span>
            </div>
            <div class="sidebar-item">
              <span class="label">MRP(Unit)</span>
              <span class="value">₹{{itemDetails.mrp | number:'1.2-2' }}</span>
            </div>
            <div class="sidebar-item" >
              <span class="label">MRP Total</span>
              <span class="value">₹{{itemDetails.mrp * itemDetails.itemQuantity | number:'1.2-2'}}</span>
            </div>
            <div class="sidebar-item">
              <span class="label">Selling Price(Unit)</span>
              <span class="value">₹{{itemDetails.mrp - (itemDetails.mrp* itemDetails.sellingDiscount /100) | number:'1.2-2'}}</span>
            </div>
            <div class="sidebar-item">
              <span class="label">Total Selling Price</span>
              <span class="value">₹{{(itemDetails.mrp - (itemDetails.mrp* itemDetails.sellingDiscount /100)) * itemDetails.itemQuantity | number:'1.2-2' }}</span>
            </div>
            <div class="sidebar-item">
              <span class="label">Discount</span>
              <span class="value">{{itemDetails.sellingDiscount}} %</span>
            </div>
            <div class="sidebar-item">
              <span class="label">Part Number</span>
              <span class="value">{{itemDetails.partNumber}}</span>
            </div>
          </div>
          <button class="okay-btn" (click)="closeSidebar()">Okay, got it</button>
        </div>
  

  <!-- Bottom Box for Selected Items -->
  <div *ngIf="activeTab === 'quote-received'">
    <div *ngIf="anyItemSelected()" class="bottom-box">
      <div class="bottom-content">
        <div>
          <div class="total-items">selectedItemsCount items</div>
          <div class="total-amount">Total Selling Price ₹{{totalSelectedPrice | number:'1.2-2'}}</div>
        </div>
      </div>
      <!-- <button class="approve-quote-button" (click)="getAllRequestPopup()">Approve Quote</button> -->
      <button class="approve-quote-button" (click)="approveQuote()" class="approve-quote-btn">Approve Quote</button>

    </div>
  </div>

</div>

 
 <div *ngIf="activeTab === 'order-created'">
  <div *ngIf="!loading">
    <div *ngFor="let list of filteredOrderList" >
      
      <div *ngIf="list.requestId== id">
      <div class="header-row0"  *ngIf="list.orderNumber !=null">  
        <div class="header-row">
          <div class="order-icon" style="width: 5%;">
            <i class="material-icons">check_box</i>
          </div>
          <div class="order-info">
            <div class="order-status">
              <h1 *ngIf="list.titleName && list.titleName !== '0 minutes'" >
                Order delivered in {{ list.titleName }}</h1>
                <h1 *ngIf="list.titleName && list.titleName === '0 minutes'">
                  Your order is in progress</h1>
              <div 
                class="delivery-status" 
                [ngClass]="{
                    'delivered-status': list.orderCurrentStatus === 'Delivered',
                    'confirmed-status': list.orderCurrentStatus === 'Confirmed',
                    'packed-status': list.orderCurrentStatus === 'Packed',
                    'on-the-way':list.orderCurrentStatus==='On The Way'}">
                  {{list.orderCurrentStatus}}
              </div>
            </div>
            <div class="request-id"  style="margin-top: 43px;" >Order No# {{list.orderNumber}}</div>

            <div class="request-id" *ngIf="list.requestId !== 0">Request ID# {{list.requestId}}</div>
            <div class="order-amount">Total Amount - ₹{{list.grandTotal}} </div>
          </div>
        </div>
        <div class="header-row1">
          <div class="order-item"><span *ngFor="let items of list.orderItems; index as i"><span *ngIf="i!=0">,</span> {{items.partName}}</span></div>
        </div>
        <div class="header-row2">
          <div class="order-date">
            <span>Placed on: {{list.createdAt | date: 'dd MMM yyyy'}}, {{list.createdAt | date: 'hh:mm a'}}</span>
            <button (click)="loadorderdetails(list.orderKey)" class="Viewbtn">
              <span class="view">View Details</span>
              <i class="material-icons">arrow_right</i> 
            </button>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>
</div>

<div #dynamicContainer1></div>
<div #dynamicContainer></div>