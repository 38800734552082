import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';
// import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspMyBookingsService {
  paramData: {};
  // private apiUrl = 'https://iapi.testpitstop.com/v1.0/pitstopspare/booking/v1/get';
  // getBookings: any;

  constructor(private http: HttpClient,) { }

  getMyBookings(garageKey: string){
    let paramData = {  
      garageKey: garageKey,
  };
      // console.log('Calling API with data:', paramData); // Log the data being sent

  return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/booking/v1/get?page=0&size=100', paramData);

}
  
  createBooking(garageKey: string, userKey: string, name: string, phoneNumber: string) {
    const paramData = {
      garageKey: garageKey,
      name: name,
      phoneNumber: phoneNumber,
      userId: userKey,
    };
    console.log('Creating booking with data:', paramData);
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/booking/v1/create', paramData);
  }

  regDecode(regNo){
    this.paramData = {};

    // console.log(this.paramData);
    console.log('-----Registration Decode-----------');    
    return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}` + '/vehicle/decode/'+regNo, this.paramData);
  }

}