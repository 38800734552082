<div class="page-layout blank fullwidth p-12" fusePerfectScrollbar>
    
    
    <mat-progress-bar class="mt-8" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <div class="order-summary mt-8 p-8">            
        <h2 class="order-summary-title">Order Summary</h2>
        <span class="order-arrival-time"><mat-icon>access_time</mat-icon> Order Arriving In 10:08 MINS</span>
        <a class="download-invoice" href="javascript:void(0);" (click)="downloadInvoice()">Download Invoice <mat-icon>save_alt</mat-icon> </a>

        <div class="order-summary-left">
            <div class="spares-added" >
                <h4 class="item-title">2 items in this order</h4>
                <!-- Items -->
                <div class="order-summary-card"> 
                    <div class="spares-part"> 
                        <img class="img-fluid part-image" src="../../../../assets/images/logos/logo.png" />
                    </div>
                    <div class="spare-details-sec">
                        <div class="spare-part-name">Item Name</div>
                        <div class="spare-brand">Brand Name</div>
                        <div class="spare-price" *ngIf="10>0; else onlyMRP">
                            <span class="selling-price">&#8377;1000</span>
                            <span class="mrp-price">&#8377;1500</span>
                            <span class="discount-price">(You save &#8377;500)</span>
                        </div>
                        <ng-template #onlyMRP>
                            <div class="spare-price-mrp">&#8377;1500</div>
                        </ng-template>
                    </div>
                </div>
                <div class="order-summary-card"> 
                    <div class="spares-part"> 
                        <img class="img-fluid part-image" src="../../../../assets/images/logos/logo.png" />
                    </div>
                    <div class="spare-details-sec">
                        <div class="spare-part-name">Item Name</div>
                        <div class="spare-brand">Brand Name</div>
                        <div class="spare-price" *ngIf="10>0; else onlyMRP">
                            <span class="selling-price">&#8377;1000</span>
                            <span class="mrp-price">&#8377;1500</span>
                            <span class="discount-price">(You save &#8377;500)</span>
                        </div>
                        <ng-template #onlyMRP>
                            <div class="spare-price-mrp">&#8377;1500</div>
                        </ng-template>
                    </div>
                </div>
                <div class="order-summary-card"> 
                    <div class="spares-part"> 
                        <img class="img-fluid part-image" src="../../../../assets/images/logos/logo.png" />
                    </div>
                    <div class="spare-details-sec">
                        <div class="spare-part-name">Item Name</div>
                        <div class="spare-brand">Brand Name</div>
                        <div class="spare-price" *ngIf="10>0; else onlyMRP">
                            <span class="selling-price">&#8377;1000</span>
                            <span class="mrp-price">&#8377;1500</span>
                            <span class="discount-price">(You save &#8377;500)</span>
                        </div>
                        <ng-template #onlyMRP>
                            <div class="spare-price-mrp">&#8377;1500</div>
                        </ng-template>
                    </div>
                </div>
                <!-- End Items -->
            </div>
            <div class="order-details">
                <h4 class="order-details-title">Order Details</h4>
                <div class="order-info">
                    <span class="info-title">Order Id</span>
                    <span class="info-details">1234567890</span>
                </div>
                <div class="order-info">
                    <span class="info-title">Payment</span>
                    <span class="info-details">Paid Online</span>
                </div>
                <div class="order-info">
                    <span class="info-title">Deliver To</span>
                    <span class="info-details">#11, 10th main, 10th cross Maruthi Nagar, Bangalore - 560068</span>
                </div>
                <div class="order-info">
                    <span class="info-title">Order Placed</span>
                    <span class="info-details">Placed on Sun, 08 Nov 2022, 12:10 PM</span>
                </div>
            </div>
        </div>
        <div class="order-summary-right">
            <div class="discount-sec" *ngIf="sparesDiscount>0">
                <p>You have saved - &#8377;{{sparesDiscount}} on this order</p>
            </div>
            <div #target  class="price-details-sec">
                <div class="order-summary-card"> 
                    <div class="price-details-title">Bill Details</div>
                    <div class="price-details">
                        <div class="price-details-left">Spares Total</div>
                        <div class="price-details-right">&#8377;{{cartSubTotal}}</div>
                    </div>
                    <div class="price-details" *ngIf="sparesDiscount>0">
                        <div class="price-details-left">Spares Discount</div>
                        <div class="price-details-right" id="discount">- &#8377;{{sparesDiscount}}</div>
                    </div>                    
                    <div class="price-details" id="shipping-charges">
                        <div class="price-details-left">Shipping Charges</div>
                        <div *ngIf="shippingChargesApplicable==true" class="price-details-right">&#8377;{{shippingCharges}}</div>
                        <div *ngIf="shippingChargesApplicable==false" class="price-details-right">
                            <span class="mrp-price">&#8377;{{shippingCharges}}</span>
                            <span id="free-shipping">FREE</span>
                        </div>
                    </div>                    
                    <div class="price-details" id="total">
                        <div class="price-details-left">Total</div>
                        <div class="price-details-right">&#8377;{{cartGrandTotal}}</div>
                    </div>
                </div>
            </div>
        </div>  

    </div>
</div>