<div class="overlay" [class.visible]="isVisible" (click)="closeSidebar(false)"></div>

<div class="sidebar" [ngClass]="{
  'visible': isVisible && !isMobile,
  'isVisibleMobile': isVisible && isMobile
}">
    <div class="order-container">
        <header>
          <div class="back-button">
            <i (click)="closeSidebar(false)" class="material-icons" style="color: black;font-weight: bold;">arrow_back</i>
             <span class="order-id">Order ID# {{orderDetails.orderNumber}}</span>
             <span class="order-total">
              ₹{{orderDetails.grandTotal |  number: '1.2-2'}}
             </span>
          </div>
          
        </header>
        <div class="savings-info">
          You have saved ₹{{orderDetails.discount}} on this order
        </div>
        <div class="delivery-info" >
          <p *ngIf="orderDeliveryTimeFormatted && orderDeliveryTimeFormatted !== '0 minutes'" style="font-weight: bold;font-size: 16px;">
          Order delivered in {{ orderDeliveryTimeFormatted }}</p>
          <p>Timely delivery of genuine spare parts every time!</p>
        </div>
        <div class="order-tracking">
            <h2>Order Tracking
              <button *ngIf="trackingUrl!=null " (click)="shareOnWhatsApp()" class="ViewbtnShare">
                <img src="../../../../assets/images/share.png" style="width: 16%;">
                <span class="viewShare">Share details</span>
              </button>
            </h2>
            
                
         <div class="progress-container">
    <div *ngFor="let status of statuses; let i = index" 
         [ngClass]="{'active': isActive(i), 'inactive': !isActive(i)}" 
         class="progress-step">
         <span class="circle">
            <i class="material-icons" style="font-size: 19px;color: white;">check</i>
          </span>
         
         <span class="orderlist" > 

          <img [src]="getStatusImage(status)" alt="{{getStatusName(status)}}" style="height: 23px; margin-top: 4px;"/>  
          
          <span *ngIf="getStatusName(status)=='Confirmed'" [ngClass]="{'active-text': isActive(i)}"> Order Confirmed </span>
          <span *ngIf="getStatusName(status)=='Packed'" [ngClass]="{'active-text': isActive(i)}"> Order Packed </span>
          <span *ngIf="getStatusName(status)=='On The Way'" [ngClass]="{'active-text': isActive(i)}"> On The Way </span>
          <span *ngIf="getStatusName(status)=='Delivered'" [ngClass]="{'active-text': isActive(i)}"> Order Delivered </span>
          
          <p class="dates" *ngIf="isActive(i)">{{ getStatusDate(status)  | date:'dd-MMM-yyyy, hh:mm a' : 'GMT+1060'  }}</p>
        </span>
      
    </div>
  </div>
  <button *ngIf="orderDetails.invoice && orderDetails.invoice.length > 0" (click)="openInvoiceUrl()" class="Viewbtn">
    <img src="../../../../assets/images/download.png" style="width: 16%;">
    <span class="view">Download invoice</span>
  </button>

        
         
        </div>
        <div class="order-items">
          <h2>{{count}} Item in this order</h2>
          
          <div *ngFor="let item of orderDetails.orderItems">
            <div class="item">
              <span *ngIf="item.discount > 0" class="discount-badge">
                {{ ((item.discount / item.price) * 100) | number:'1.0-0' }}% Off
              </span>
              <img [src]="item.imageUrl" alt="{{item.partName}}">
              <div class="item-details">
                <h3>{{item.partName}}</h3>
                <p>{{item.brandName}}</p>
                <p>{{item.partNumber}}</p>
                <!-- <p>quantity {{item.quantity}}</p> -->
                <div class="price">
                  
                  <span class="discounted-price" >₹{{item.price *item.quantity |  number: '1.2-2'}}</span>
                  <span class="original-price" *ngIf="item.discount>0">₹{{(item.price+item.discount)*item.quantity |  number: '1.2-2'}}</span>
                </div>
                <p class="savings" *ngIf="item.discount>0">You save ₹{{item.discount * item.quantity |  number: '1.2-2'}}</p>
              </div>
            </div>
        </div>
        </div>
        <div class="price-details">
          <h2 class="priceDet">Price Details</h2>
          <div class="details">
            <p>Spares Total <span>₹{{orderDetails.grandTotal+orderDetails.discount |  number: '1.2-2'}}</span></p>
            <!-- <p *ngIf="orderDetails.grandTotal+orderDetails.discount > 1999" >Shipping Charges
              <i class="maticon" mat-raised-button matTooltip="Provides free Shipping for orders above ₹1999" (click)="openBottomSheet()">
              info_outline
            </i>
              <span style="text-decoration: line-through;margin-left: 142px;">₹60</span>
              <span style="color: #4caf50;">Free</span>
            </p>

            <p *ngIf="orderDetails.grandTotal+orderDetails.discount <= 1999">Shipping Charges
              <i class="maticon" mat-raised-button matTooltip="Provides free Shipping for orders above ₹1999" (click)="openBottomSheet()">
              info_outline
            </i>
            
               <span>₹60</span>
              </p>
                  <ng-template #bottomSheetTemplate >
                    <div class="bottom-sheet-content">
                      <i class="material-icons" (click)="closeBottomSheet()" style="float: left;font-size: 17px;">cancel</i>                    
                      <h3 style="font-weight: bold;">Shipping Charge</h3>
                      <p style="font-size: 13px;">Autoverse provides free Shipping for orders above ₹1999</p>
                      <button mat-button class="custom-button" (click)="closeBottomSheet()">Okay, Got it</button>
                    </div>
                  </ng-template> -->

            <p >Spares Discount <span style="color: #4caf50;">₹{{orderDetails.discount |  number: '1.2-2'}}</span></p>
            <p style="font-weight: bold; color: black;">Total <span style="color: black; font-weight: bold;">₹{{orderDetails.grandTotal |  number: '1.2-2'}}</span></p>
          </div>
        </div>
        <div class="order-details">
          <h2 class="priceDet">Order Details</h2>
          <h4 style="margin-bottom: -11px;">Payment</h4>
          <p>{{orderDetails.paymentMethod}}</p>
          <h4 style="margin-bottom: -11px;">Delivered To
          </h4>
          
          <p>{{orderDetails.shippingAddress}}</p>
          <h4 style="margin-bottom: -11px;">Order placed</h4>
          <p>{{orderDetails.orderDate | date:'dd-MMM-yyyy, hh:mm a': 'GMT+1060'}}</p>
        </div>
        <button (click)="openTracking()" class="ViewbtnTrack">
          <img src="../../../../assets/images/order-details/orderTrack.png" style="width: 14%;">
          <span class="viewTrack">Track your order</span>
        </button>
        <div class="fixed-reorder">
          
        <button class="reorder-button" (click)="getReorder(orderKey)" *ngIf="orderDetails.orderCurrentStatus=='Delivered'" [ngClass]="{'active-text': isActive(index)}">Reorder</button>
        <button class="reorder-button" (click)="openTracking()" *ngIf="orderDetails.orderCurrentStatus!='Delivered' && trackingUrl!=null " [ngClass]="{'active-text': isActive(index)}">Track Order</button>
      </div>
    
      </div>
  </div>
