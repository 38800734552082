import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AspOrderListService } from '../asp-order-list/asp-order-list.service';
import { Router } from '@angular/router';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


@Component({
  selector: 'app-asp-order-details',
  templateUrl: './asp-order-details.component.html',
  styleUrls: ['./asp-order-details.component.scss']
})
export class AspOrderDetailsComponent implements OnInit {
  @Input() order: any;
  @Input() isVisible: boolean = false;

  images = {
    'Confirmed': "assets/images/order-details/confirmed.png",
    'Packed': "assets/images/order-details/packed.png",
    'On The Way': "assets/images/order-details/way.png",
    'Delivered': "assets/images/order-details/delivered.png"
  };
  orderDetails: any = [];
  count: any = 0;
  currentStatus: any = '';
  invoiceUrl: string | undefined;
  

  statuses = ['orderConfirm', 'orderPacked', 'orderOnTheWay', 'orderDelivery'];
  statusNames = ['Order Confirmed', 'Packed', 'On The Way', 'Delivered'];
  statusDates: { [key: string]: string } = {};
  currentStatusIndex: number = -1;
  orderDeliveryTimeFormatted: string = '';
  trackingUrl:  string | undefined;
  orderKey: any;
  addedCart: any;
  orderNumber: any;
  spareSelectedGarageName: any;
  isMobile: boolean;

  constructor(
    private aspOrderListService: AspOrderListService,
    private router: Router,
    private bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); }
  @ViewChild('bottomSheetTemplate') bottomSheetTemplate!: TemplateRef<any>;
  
  ngOnInit() {
    if (this.order && this.order.key) {
      this.getOrderDetails(this.order.key);
      this.getReorder(this.order.key);
    }
    this.spareSelectedGarageName = localStorage.getItem("spareSelectedGarageName");
  }

  closeSidebar(ishide: boolean) {
    this.isVisible = ishide;
  }
  openBottomSheet(): void {
    this.bottomSheet.open(this.bottomSheetTemplate,{
     panelClass: 'custom-bottom-sheet'
    }
    );
  }

  closeBottomSheet(): void {
    if (this.bottomSheet) {
      this.bottomSheet.dismiss();
    }
  }

  getOrderDetails(key: string) {
    this.orderKey=key;
    // console.log('key', key);
    this.aspOrderListService.getorderdetails(key).subscribe(
      res => {
        // console.log('res11',res);
        this.orderDetails = res['data'];
        this.count = res['data'].orderItems.length;
        // console.log(this.orderDetails);
        this.setCurrentStatus(res['data']);
        this.trackingUrl= res['data'].trackingUrl;
        this.orderNumber=res['data'].orderNumber;
        if (res['data'].invoice && res['data'].invoice.length > 0) {
          this.invoiceUrl = res['data'].invoice[0].invoiceUrl;
        }
        this.formatOrderDeliveryTime(res['data'].orderConfirmTime, res['data'].orderDeliveryTime);
      }
    );
  }

  setCurrentStatus(data: any) {
    const statusOrder = ['Confirmed', 'Packed', 'On The Way', 'Delivered'];
    const statuses = [data.orderConfirm, data.orderPacked, data.orderOnTheWay, data.orderDelivery];
    const dates = [data.orderConfirmTime, data.orderPackedTime, data.orderOnTheWayTime, data.orderDeliveryTime];

    this.currentStatusIndex = -1;
    this.statusDates = {};

    // Determine the highest status index to set as the current status
    for (let i = 0; i < statuses.length; i++) {
      if (statuses[i] === statusOrder[i]) {
        this.currentStatusIndex = i;
        this.statusDates[statusOrder[i]] = dates[i];
      }
      if (dates[i]) {
        this.statusDates[statusOrder[i]] = dates[i];
      }
    }
  }

  getStatusName(status: string) {
    switch (status) {
      case 'orderConfirm':
        return 'Confirmed';
      case 'orderPacked':
        return 'Packed';
      case 'orderOnTheWay':
        return 'On The Way';
      case 'orderDelivery':
        return 'Delivered';
      default:
        return '';
    }
  }
  getStatusDate(status: string) {
    return this.statusDates[this.getStatusName(status)] || 'Not Available';
  }

  isActive(index: number) {
    return index <= this.currentStatusIndex;
  }

  navigateToCart(): void {
    if (this.orderDetails && this.orderDetails.orderItems) {
      // Assuming you have a CartService that handles the cart data
      this.orderDetails.orderItems;
      this.router.navigate(['/cart']); 
      // console.log("cartD", this.orderDetails.orderItems)
    } else {
      console.error('Order details or order items are not available');
    }
  }

  getTotalPrice(): number {
    let totalPrice = 0;
    for (let item of this.orderDetails.orderItems) {
      totalPrice += item.price * item.quantity;
    }
    return totalPrice;
  }
  getStatusImage(status: string): string {
    return this.images[this.getStatusName(status)] || '';
  }
  formatOrderDeliveryTime(confirmTime: string, deliveryTime: string): void {
    try {
      const confirmDate = new Date(confirmTime.replace(" ", "T"));
      const deliveryDate = new Date(deliveryTime.replace(" ", "T"));

      if (isNaN(confirmDate.getTime()) || isNaN(deliveryDate.getTime())) {
        console.error('Invalid date format:', confirmTime, deliveryTime);
        this.orderDeliveryTimeFormatted = 'Invalid time data';
        return;
      }

      const diffMs = deliveryDate.getTime() - confirmDate.getTime();
      const diffMins = Math.floor(diffMs / 60000);

      if (diffMins < 0) {
        console.error('Delivery time is earlier than confirm time:', diffMins);
        this.orderDeliveryTimeFormatted = 'Invalid time data';
        return;
      }

      if (diffMins <= 180) {
        this.orderDeliveryTimeFormatted = `${diffMins} minutes`;
      } else {
        const hours = Math.floor(diffMins / 60);
        const minutes = diffMins % 60;
        this.orderDeliveryTimeFormatted = `${hours}H ${minutes} min`;
      }

      // console.log('Order Delivery Time Formatted:', this.orderDeliveryTimeFormatted);
    } catch (error) {
      console.error('Error formatting delivery time:', error);
      this.orderDeliveryTimeFormatted = 'Invalid time data';
    }
}
openInvoiceUrl(): void {
  if (this.invoiceUrl) {
    window.open(this.invoiceUrl, '_blank');
  } else {
    console.error('Invoice URL is not available');
  }
}
openTracking(): void {
  
  // console.log('trackingUrl',this.trackingUrl)
  if (this.trackingUrl !=null) {
    window.open(this.trackingUrl, '_blank');
  } else {
    console.error('Invoice URL is not available');
  }
}

getReorder(key: string) { 
  sessionStorage.removeItem('addedCart');
  this.aspOrderListService.getReorder(key).subscribe(
    res => {
      // console.log('reorder',res);
      if (res['code'] === 200) {
        let reorder = {};
        this.addedCart = []
    
        res['data'].forEach(item => {
            item.cart_qty = 1;
            item.spareStocks = {
              "partNumber": item.partNumber,
              "discountAmount": item.discount,
              "discountType": item.discountType,
              "mrp": item.mrp,
              "sellingPrice": item.sellingPrice,
              "quantity": item.count
            };
            reorder[item.key] = item;
            this.addedCart.push(reorder);
        });
    
        sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
        this.router.navigate(['cart']);
    }
    }
  );
  }

  shareOnWhatsApp() {
    const garageName=this.spareSelectedGarageName
    const orderNumber = this.orderNumber;
    const deepLink = `http://www.autoversemobility.in/order/${this.orderKey}`;
    const message = `🚗  Track Your Order Now! 🚗 \n\nHey ${garageName},\n\nTrack your order (Order No: ${orderNumber}) in the Autoverse Spares App.\n\n📱  Click here to track: ${deepLink}`;
  
    const whatsappUrl = `https://web.whatsapp.com/send?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
    
  
  }
}
