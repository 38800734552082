import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';


@Injectable({
  providedIn: 'root'
})
export class AspPaymentService {


  constructor(
    private http: HttpClient,
  ) { }

  getUserDetails(garageKey){    
    console.log('-----Get User Details-----------');    
    return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}` + '/garage/address/getAll/'+garageKey);
  }
  createOrder(shippingAddressId, billingAddressId, paymentMethod, razorPayPaymentId, orderKey, cod, grandTotal, walletAmountToUse, walletChecked){
    let userKey = localStorage.getItem('spareUserKey');
    let agentKey = localStorage.getItem('agentKey');
    let rowKey = localStorage.getItem('rowKey') || '';
    let variantKey = localStorage.getItem('variantKey') || '';
    let garageKey = localStorage.getItem('garageKey');

    let cartList = [];
    if (sessionStorage.getItem('addedCart')) {
        let cartData = JSON.parse(sessionStorage.getItem('addedCart'));
        Object.values(cartData[0]).forEach(cart => {
            cartList.push({"partKey": cart['key'], "quantity": cart['cart_qty']});
        });
    }

    let paramData = {      
      billingAddressId: billingAddressId,
      cod: cod,
      data: [{"items": cartList, "rowKey": rowKey,"variantKey": variantKey}],
      garageKey: garageKey,
      // id: agentKey,
      orderKey: orderKey,
      userKey:userKey,
      paymentMethod: paymentMethod,
      razorPayPaymentId: razorPayPaymentId,
      shippingAddressId: shippingAddressId,
      userType: localStorage.getItem('userType')   
    };

    // Handle Payment Method Logic
    if (paymentMethod == 1) {
      // COD Payment
      paramData['payment'] = {
        "online": false,
        "onlineAmount": 0.0,
        "onlinePaymentId": '',
        "onlinePaymentMethod": 1,
        "wallet": walletChecked,
        "walletAmount": walletAmountToUse
      };
    } else if (paymentMethod == 2) {
      // Online Payment
      paramData['payment'] = {
        "online": true,
        "onlineAmount": grandTotal,
        "onlinePaymentId": razorPayPaymentId,
        "onlinePaymentMethod": 2, // Set to 2 for online payment
        "wallet": walletChecked,  // Only include wallet if it's used
        "walletAmount": walletAmountToUse
      };
    }

    console.log('-----Create Order-----------');    
    console.log("paramData",paramData);
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/v2/order/create', paramData);
    
}


  createRazorPayOrder(orderKey, userKey, garageKey, grandTotal, orderType, userType, agentKey){
    let paramData = {      
      orderKey: orderKey,
      // userKey: userKey,
      garageKey: garageKey,
      amount: grandTotal,
      orderType: orderType,
      agentKey: agentKey,
      userType: userType
    };
    // console.log(`${GlobalVariable.BASE_API_URL_SPARES}` + '/payment/createRazorPayOrder');
    // console.log(paramData);
    console.log('-----Create RazorPay Order-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/payment/createRazorPayOrder', paramData);
  
  }

  verifyPayment(razorPayPaymentId, razorPayOrderId, razorPaySignature, orderKey){
    let paramData = {      
      razorPayPaymentId: razorPayPaymentId,
      razorPayOrderId: razorPayOrderId,
      razorPaySignature: razorPaySignature,
      orderKey: orderKey
    };

    // console.log(`${GlobalVariable.BASE_API_URL_SPARES}` + '/payment/verifyPayment');
    // console.log(paramData);
    console.log('-----Verify Payment-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/payment/verifyPayment', paramData);
  
  }

  getWallet(garageKey, userKey) {    
    console.log('-----Get Wallet-----------');
    // return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}` + '/garage/payment/getPaymentMethods/' +garageKey);
        return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}` + '/user/payment/methods/' + userKey+'/'+garageKey);

  }
}
