<div class="loading-container" >
    <div class="loading-background"></div>
    
    <ng-lottie
    style="width:200px; height:400px"
    [options]="options"
    (animationCreated)="animationCreated($event)">
  </ng-lottie>
  <p class="message">{{ randomMessage }}</p>
  
  </div>