import { Component, ElementRef, ViewChild, OnInit, ComponentFactoryResolver, ViewContainerRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AspMyRequestService } from '../../auto-spare-parts/asp-my-request/asp-my-request.service';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { AspLoadingComponent } from '../asp-loading/asp-loading.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


interface FilterOption {
  label: string;
  value: string;
  checked: boolean;
  children?: FilterOption[];
}

interface Filters {
  requestStatus: FilterOption[];
  requestTime: FilterOption[];
}

@Component({
  selector: 'app-asp-my-request',
  templateUrl: './asp-my-request.component.html',
  styleUrls: ['./asp-my-request.component.scss']
})
export class AspMyRequestComponent implements OnInit, AfterViewInit {
  emptyList: any = 'assets/images/no-cart.png';
  @ViewChild('filterSection', { static: false }) filterSection!: ElementRef;
  @ViewChild('dynamicContainers', { read: ViewContainerRef }) containers!: ViewContainerRef;

  filters: Filters = {
    requestStatus: [
      { label: 'Requested', value: '1', checked: false },
      { label: 'Quote Received', value: '8', checked: false },
      { label: 'Quote Approved', value: '4', checked: false },
    ],
    requestTime: [
      { label: 'Today', value: 'TODAY', checked: false },
      { label: 'Yesterday', value: 'YESTERDAY', checked: false },
      { label: 'Last 7 days', value: 'LAST_7_DAYS', checked: false },
      { label: 'Last 30 days', value: 'LAST_30_DAYS', checked: false },
      { label: 'This month', value: 'THIS_MONTH', checked: false },
      { label: 'Last Month', value: 'LAST_MONTH', checked: false },
      { label: 'Any Time', value: 'ANY_TIME', checked: false }
    ]
  };

  loading: boolean = false;
  requestList: any[] = [];
  filteredRequestList: any[] = [];
  searchTerm: string = '';
  selectedRequestStatus: string = '';
  selectedRequestTime: string = '';
  make: any = '';
  statusCount: { [key: string]: number } = {};
  showClearIcon: boolean;
  filterOrders: any;
  requestDetails: any;
  list: any;


  @ViewChild(MatSort, { static: false }) sort: MatSort;
  getRelevance: any;
  isMobile: boolean;
  isSidebarOpen: boolean;

  constructor(
    private aspMyRequestService: AspMyRequestService,
    private router: Router,
    private resolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); }

  ngOnInit(): void {
    this.getList();
    
  }
  ngAfterViewInit() {
    this.loadingMsg();
    this.cdr.detectChanges();
  }
  sidecontainer() {
    this.isSidebarOpen = !this.isSidebarOpen; // Toggle the sidebar open/close state
  }
  loadingMsg() {
    
    const factory = this.resolver.resolveComponentFactory(AspLoadingComponent);
    
   

    if (this.containers) {
      const componentRef = this.containers.createComponent(factory);
      

      const componentBInstance = componentRef.instance as AspLoadingComponent;
      componentBInstance.getLoadingMsg();
    } else {
      console.error('containers is undefined');
    }
  }
  
  navigateToRequestDetails(): void {
    this.router.navigate(['request-details']); 
  }
  // getMakeImageUrl(make: string): string {
  //   return `https://cube.getpitstop.com/assets/img/${make.toLowerCase()}.png`;
  // }

  getMakeImageUrl(make: string): string {
    const makeWithoutSpaces = make.toLowerCase().replace(/\s+/g, '');
    return `https://cube.getpitstop.com/assets/img/${makeWithoutSpaces}.png`;
  }
  

  getStatusMessage(statusDetails: { [key: string]: number } | undefined): string {
    // console.log(statusDetails);
    if (!statusDetails) return 'Requested';
    // for (const [key, value] of Object.entries(statusDetails)) {
      // console.log(`Key: ${key}, Value: ${value}`);

      let statusMessage = 'Requested';  
      for (const [key, value] of Object.entries(statusDetails)) {
        // console.log(`Value: ${key}`);
        
        if (key === '4') {
            return 'Quote Approved';  
        } else if (key === '8') {
            statusMessage = 'Quote Received';  
        }
    }
    
    return statusMessage;
}
  toggleSelection(filterType: keyof Filters, option: FilterOption) {
    
    if (filterType === 'requestStatus') {
      this.filters.requestStatus.forEach(status => {
        status.checked = (status.value === option.value);
      });
      this.selectedRequestStatus = option.value;

    } else if (filterType === 'requestTime') {
      this.filters.requestTime.forEach(time => {
        time.checked = (time.value === option.value);
        if (time.children) {
          time.children.forEach(child => {
            child.checked = (child.value === option.value);
          });
        }
      });
      this.selectedRequestTime = option.value;
    }
    this.filterRequest();
  }

  clearFilters() {
    this.selectedRequestStatus = '';
    this.selectedRequestTime = '';
    const clearOptions = (options: FilterOption[]) => {
      options.forEach(option => {
        option.checked = false;
        if (option.children) {
          clearOptions(option.children);
        }
      });
    };

    (Object.keys(this.filters) as (keyof Filters)[]).forEach(requestTimeKey => {
      clearOptions(this.filters[requestTimeKey]);
    });
    this.filterRequest();
  }

  hasAppliedFilters(): boolean {
    return this.selectedRequestStatus !== '' || this.selectedRequestTime !== '';
  }

  getAppliedFilters(): { requestTime: keyof Filters, option: FilterOption }[] {
    const appliedFilters: { requestTime: keyof Filters, option: FilterOption }[] = [];

    const addFilter = (requestTimeKey: keyof Filters, option: FilterOption) => {
      if (option.checked) {
        appliedFilters.push({ requestTime: requestTimeKey, option });
      }
    };

    (Object.keys(this.filters) as (keyof Filters)[]).forEach(requestTimeKey => {
      this.filters[requestTimeKey].forEach(option => {
        addFilter(requestTimeKey, option);
        if (option.children) {
          option.children.forEach(child => {
            addFilter(requestTimeKey, child);
          });
        }
      });
    });

    return appliedFilters;
  }

  removeFilter(requestTimeKey: keyof Filters, option: FilterOption) {
    option.checked = false;
    if (requestTimeKey === 'requestStatus') {
      this.selectedRequestStatus = '';
    } else if (requestTimeKey === 'requestTime') {
      this.selectedRequestTime = '';
    }
    this.filterRequest();
  }

  getList() {
    this.loading = true;
  let spareUserKey = localStorage.getItem('spareUserKey');
  let garageKey = localStorage.getItem('garageKey');
  this.aspMyRequestService.myRequest(spareUserKey, garageKey).subscribe(
    result => {
       console.log("my-request-response",result)
      let res = JSON.parse(JSON.stringify(result));
      if (res.code === 200) {
        this.loading = false;
        this.requestList = res.data.sort((a, b) => {    
          return new Date(b.requestDetails.requestedDate).getTime() - new Date(a.requestDetails.requestedDate).getTime();
        });
        this.filteredRequestList = res.data;
        this.make = res.data;
        this.computeStatusCounts();

      } else {
        this.loading = false;
      }
    }
  );
}

filterRequest() {
  
  this.filteredRequestList = this.requestList.filter(request => {
    let statusMatch;
    if(request.statusDetails){
      Object.keys(request.statusDetails).some(key => {
        statusMatch = this.selectedRequestStatus ? key === this.selectedRequestStatus : true;
      });
    }
    const timeMatch = this.selectedRequestTime ? this.filterByTime(request.requestDetails.requestedDate, this.selectedRequestTime) : true;
    const searchMatch = this.searchTerm.trim() ? request.requestDetails.requestId.includes(this.searchTerm.trim()) : true;
    return statusMatch && timeMatch && searchMatch;
  });



  

//   this.walletInfoData.transactions.sort((a, b) => {
//     return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
// });

  // console.log('Filtered Request List:', this.filteredRequestList);
  }

  filterByTime(requestTime: string, filter: string): boolean {
    const now = new Date();
    const requestDate = new Date(requestTime);

    switch (filter) {
      case 'TODAY':
        return requestDate.toDateString() === now.toDateString();
      case 'YESTERDAY':
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);
        return requestDate.toDateString() === yesterday.toDateString();
      case 'LAST_7_DAYS':
        const weekAgo = new Date(now);
        weekAgo.setDate(now.getDate() - 7);
        return requestDate >= weekAgo && requestDate <= now;
      case 'LAST_30_DAYS':
        const monthAgo = new Date(now);
        monthAgo.setDate(now.getDate() - 30);
        return requestDate >= monthAgo && requestDate <= now;
      case 'THIS_MONTH':
        const thisMonthStart = new Date(now.getFullYear(), now.getMonth(), 1);
        const nextMonthStart = new Date(now.getFullYear(), now.getMonth() + 1, 1);
        return requestDate >= thisMonthStart && requestDate < nextMonthStart;    
      case 'LAST_MONTH':
        const lastMonthStart = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const lastMonthEnd = new Date(now.getFullYear(), now.getMonth(), 0);
        return requestDate >= lastMonthStart && requestDate <= lastMonthEnd;
      case 'ANY_TIME':
      default:
        return true;
    }
  }

  computeStatusCounts() {
    this.statusCount = this.requestList.reduce((counts, request) => {
      if (request.statusDetails) {
        Object.keys(request.statusDetails).forEach(status => {
          counts[status] = (counts[status] || 0) + request.statusDetails[status];
        });
      }
      
      return counts;
    }, {} as { [key: string]: number });
  }


  clearSearch() {
    this.searchTerm = '';
    this.showClearIcon = false;
    this.filterRequest();
  }

  // Method to sort suggestions by relevance
// sortSuggestionsByRelevance(suggestions: string[], searchText: string): string[] {
//   return suggestions.sort((a, b) => {
//       const relevanceA = this.getRelevance(a, searchText);
//       const relevanceB = this.getRelevance(b, searchText);
//       return relevanceB - relevanceA; // Higher relevance comes first
//   });
// }
navigateToDetails(id: string, regno: string) {
  this.router.navigate(['/request-details'], { queryParams: {"id": id, "regNo":regno}})
}
}
