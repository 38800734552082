import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AspHomeService } from './asp-home.service';
import { ToastrService } from 'ngx-toastr';
import { AspLoginService } from '../asp-login/asp-login.service';
import { fuseAnimations } from '@fuse/animations';
import { FormBuilder, FormGroup, Validators,AbstractControl, ValidatorFn} from '@angular/forms';
import { AspMakeComponent } from '../asp-make/asp-make.component';
import { AspModelComponent } from '../asp-model/asp-model.component';
import { AspYearComponent } from '../asp-year/asp-year.component';
import { AspVariantComponent } from '../asp-variant/asp-variant.component';
import { AspMakeService } from '../asp-make/asp-make.service';
import { AspDynamicPopupComponent } from '../asp-dynamic-popup/asp-dynamic-popup.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {SearchSuggestionService} from 'app/shared/search-suggestion.service'


export function maxLengthValidator(maxLength: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value && control.value.length > maxLength) {
      return { 'maxLength': { value: control.value } };
    }
    return null;
  };
}



@Component({
  selector: 'app-asp-home',
  templateUrl: './asp-home.component.html',
  styleUrls: ['./asp-home.component.scss'],  
  animations: fuseAnimations
})
export class AspHomeComponent implements OnInit {  
  loading: boolean = false;
  spareSelectedGarageName: any;
  brands: any = [];
  categories: any = [];
  allCategories: any = [];
  veiwMoreCat: boolean = true;
  veiwLessCat: boolean = false;  
  formData: any = {}; 
  vehicleForm: FormGroup;  
  submitted = false;
  makeList: any = [];
  vehicleInfo: boolean = false;
  vehicleData: any = [];
  searchText = ''
  suggestions: string[] = [];
  selectedSuggestions: Set<string> = new Set<string>();

  components = {
    makeComp: AspMakeComponent,
    modelComp: AspModelComponent,
    yearComp: AspYearComponent,
    variantComp: AspVariantComponent
  };
  isMobile: boolean = false;
  isInputVisible: boolean;

  constructor(
    private router: Router,
    private homeService: AspHomeService,
    public toastr: ToastrService,
    private aspLoginService: AspLoginService,    
    private _formBuilder: FormBuilder,
    private aspMakeService: AspMakeService,
    
    private bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver,
    private searchSuggestionService:SearchSuggestionService,
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); }

  
  ngOnInit(): void { 
    localStorage.removeItem('isDynamicPopup'); 
    this.loading = true;    
    this.vehicleForm = this._formBuilder.group({
        regNo: ['', [Validators.required,maxLengthValidator(12)]]
    });

    this.vehicleForm.get('regNo')?.valueChanges.subscribe(value => {
      if (value) {
        // this.vehicleForm.get('regNo')?.setValue(value.toUpperCase(), { emitEvent: false });
        const formattedValue = this.formatRegNo(value);
        this.vehicleForm.get('regNo')?.setValue(formattedValue, { emitEvent: false });
      }
    });
    
    this.aspLoginService.checkLogin();
    this.spareSelectedGarageName = localStorage.getItem("spareSelectedGarageName");
    this.getPopularBrandsAndCategory();
    this.getTopMake();

    this.vehicleData = JSON.parse(localStorage.getItem("vehicleData"));
    if(this.vehicleData){
      this.vehicleInfo = true;
    }else{
      this.vehicleInfo = false;
    }
    
    this.clearLocalStorage();
  }

  get f() { return this.vehicleForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.vehicleForm.invalid) {
        return;
    }

    this.homeService.regDecode(this.f.regNo.value.replace(/\s/g, "")).subscribe(
      res => {
        if(res['code']==200){
          this.loading = false;
          this.vehicleInfo = true; 
          this.vehicleData = res['data'];
          localStorage.setItem("vehicleData", JSON.stringify(this.vehicleData));  
        }else{
          this.toastr.error(res['data']['description'], 'Error');          
          this.loading = false;
        }
      }
    );
  }
  formatRegNo(value: string): string {
    // Remove all spaces and uppercase the value
    value = value.replace(/\s+/g, '').toUpperCase();

    // Insert spaces after the first 2 letters and the first 2 digits
    if (value.length > 2) {
        value = value.slice(0, 2) + ' ' + value.slice(2);
    }
    if (value.length > 5) {
        value = value.slice(0, 5) + ' ' + value.slice(5);
    }

    return value;
  }

  goToMake = () => {
    this.clearSelectedBrandCat();
    
    this.router.navigate(['make']);
  }

  getPopularBrandsAndCategory = () => {
    this.loading = true;
    this.homeService.popularBrandsAndCategory().subscribe(
      res => {
        if(res && res['code']==200){
          this.loading = false;

          if(res['data']['brands'])
            this.brands = res['data']['brands'];

          if(res['data']['categories'])
            this.categories = res['data']['categories'];


        }else{    
          // this.closePopup();
          // this.router.navigate(['home']);  
          this.toastr.error(res['message'], 'Error');
          
          this.loading = false;
        }
      }
    )
  }

  goToAllBrands = () => {
    this.router.navigate(['all-brands']);
  }

  getAllCategories = () => {
    this.loading = true;
    this.homeService.getAllCategories().subscribe(
      res => {
        // console.log(res);
        if(res && res['code']==200){
          this.loading = false;
          this.veiwMoreCat = false;
          this.veiwLessCat = true;

          if(res['data']){
            this.allCategories = res['data'].sort((a, b) => a.priority - b.priority);
          }
          // console.log(this.allCategories);
        }else{    
          this.toastr.error(res['message'], 'Error');          
          this.loading = false;
        }
      }
    )
  }

  viewLessBrands = () => {    
    this.veiwMoreCat = true;
    this.veiwLessCat = false;
  }

  // brandSparesList = (brand:any, brandImg:any) => {
  //   this.clearSelectedBrandCat();

  //   localStorage.setItem("selectedBrand",  brand);   
  //   localStorage.setItem("selectedBrandImg",  brandImg);   
  //   localStorage.setItem("vehicleType",  'universal');   
  //   this.router.navigate(["brand-spares-list"]);
  // }

  // categorySparesList = (brand:any, brandImg:any) => {
  //   this.clearSelectedBrandCat();
      
  //   localStorage.setItem("selectedCategory",  brand);   
  //   localStorage.setItem("selectedCategoryImg",  brandImg);  
  //   localStorage.setItem("vehicleType",  'universal');    
  //   this.router.navigate(["category-spares-list"]);
  // }

  clearSelectedBrandCat(){
    if(localStorage.getItem("selectedBrand")){
      localStorage.removeItem('selectedBrand');
    }
    
    if(localStorage.getItem("selectedBrandImg")){
      localStorage.removeItem('selectedBrandImg');
    }
    
    if(localStorage.getItem("selectedCategory")){
      localStorage.removeItem('selectedCategory');
    }

    if(localStorage.getItem("selectedCategoryImg")){
      localStorage.removeItem('selectedCategoryImg');
    }
  }

  // onSubmit() {
  //   if(this.formData.partNumber){
  //     localStorage.removeItem('searchPartNumber');
  //     localStorage.setItem("searchPartNumber",  this.formData.partNumber);  
      
  //     this.clearSelectedBrandCat();
  //     this.router.navigate(['spare-parts-list']);
  //   }
  // }

  getTopMake() {
    let data = sessionStorage.getItem('spareVehicleDetails');   
    let vehicleDetails;
    
    if(data){
      this.loading = false;
      vehicleDetails = data;
      let res = JSON.parse(vehicleDetails); 
      Object.values(res.data).forEach(make => {
        if(make[0]['title']!='Universal' && this.makeList.length<=6){
          this.makeList.push(make[0]);
        }
      });
    }else{
      this.loading = true;
      this.aspMakeService.getMake().subscribe(
        result => {
          this.loading = false;
          // console.log(result);
          let vehicleDetails = JSON.stringify(result);          
          this.setVehicleDetails(vehicleDetails);
          
          let res = JSON.parse(vehicleDetails); 
          
          Object.values(res.data).forEach(make => {
            if(make[0]['title']!='Universal' && this.makeList.length<=6){
              this.makeList.push(make[0]);
            }
          });
        }
      )
    }
  }

  setVehicleDetails(data){
    sessionStorage.setItem('spareVehicleDetails', data); 
  }

  selectMake(make: any) {
    this.loading = true;
    if(localStorage.getItem('selectedMake') && localStorage.getItem('selectedModel') && localStorage.getItem('selectedYear') && localStorage.getItem('variant'))
      localStorage.setItem('newMake', make); 
    else
      localStorage.setItem('selectedMake', make);  

    this.router.navigate(['model']);

  }

  changeCar() {
    localStorage.removeItem('vehicleData');
    this.ngOnInit();
  }

  confirmCar() {
    let vehicleData = JSON.parse(localStorage.getItem('vehicleData'));


    localStorage.setItem('selectedMake', vehicleData['make']);
    localStorage.setItem('selectedModel', vehicleData['model']);
    localStorage.setItem('rowKey', vehicleData['rowKey']);
    localStorage.setItem('selectedYear', vehicleData['year']);
    localStorage.setItem('variant', vehicleData['variant']);
    localStorage.setItem('variantKey', vehicleData['variantKey']);
    this.sparesList(vehicleData['make']+' '+vehicleData['model'], 'allSpares')
    // this.router.navigate(['spare-parts-list']);
  }

  sparesList(name, type, imageUrl='',key=''){
    localStorage.setItem('selectedSparesList', JSON.stringify({"name": name, "type": type, "imageUrl": imageUrl,"key":key}));
    localStorage.setItem('isDynamicPopup',"true")
    this.router.navigate(['spare-parts-list']);
    // if(type=='brandSpares'|| type=='categorySpares'){
    //   this.bottomSheet.open(AspDynamicPopupComponent, {
    //     panelClass: 'bottom-sheet-missmach'  
    //   });
    // }
  }
  
  private clearLocalStorage(){
    sessionStorage.removeItem('sparePartsDetails');
    localStorage.removeItem('vehicleType');
    localStorage.removeItem('newMake');
    localStorage.removeItem('newModel');
    localStorage.removeItem('newYear');
    localStorage.removeItem('newVariant');
    localStorage.removeItem('newVariantKey');
    localStorage.removeItem('newRowKey');
    localStorage.removeItem('isShippingCharges');
    localStorage.removeItem('prepayUpiDiscount');
    // localStorage.removeItem('vehicleData');
    localStorage.removeItem('selectedModel');
    localStorage.removeItem('selectedYear');
    localStorage.removeItem('variant');
    localStorage.removeItem('selectedFuel');
    localStorage.removeItem('variantKey');
    localStorage.removeItem('rowKey');
    localStorage.removeItem('selectedSparesList');
    localStorage.removeItem('preSearchValue');
  };
  redirectToVehicleForm() {
    this.router.navigate(['vehicle']);
  }

  toggleSearch() {
    this.isInputVisible = !this.isInputVisible;
    if (this.isInputVisible) {
      setTimeout(() => {
        const input = document.querySelector('.SearchInput') as HTMLInputElement;
        if (input) {
          input.focus();
        }
      }, 0);
    }
  }

  clearSearch(event: Event) {
   event.stopPropagation();
    // this.searchText = ''; // Clear the search text
    this.isInputVisible = false; // Hide the input field if needed
    this.filterSuggestions();
  }

    
  filterSuggestions() {
    if (this.searchText && this.searchText.length >= 3) {
        let rowKey = localStorage.getItem('rowKey');
        
        let universal = false;
        let variantKey = localStorage.getItem('variantKey');
        
        this.searchSuggestionService.getSearchSuggestions(rowKey, this.searchText, universal, variantKey).subscribe(
            result => {


                this.suggestions = [];
                if (result['data'].partNumbers && result['data'].partNumbers.length > 0)
                    this.suggestions.push(...result['data'].partNumbers.map((value: string) => ({ value, type: 'partNumber' })));
                if (result['data'].brands && result['data'].brands.length > 0)
                    this.suggestions.push(...result['data'].brands.map((value: string) => ({ value, type: 'brand' })));
                if (result['data'].categories && result['data'].categories.length > 0)
                    this.suggestions.push(...result['data'].categories.map((value: string) => ({ value, type: 'category' })));
                if (result['data'].partNames && result['data'].partNames.length > 0)
                    this.suggestions.push(...result['data'].partNames.map((value: string) => ({ value, type: 'partName' })));


                // Sort suggestions based on relevance
                this.suggestions = this.sortSuggestionsByRelevance(this.suggestions, this.searchText);

            }
        );
    } else {
        this.suggestions = [];
    }
}

selectSuggestion(suggestion: { value: string, type: string }) {
  this.selectedSuggestions.add(suggestion.value);
  this.searchText = '';
  this.filterSuggestions();
  this.isInputVisible = false;
  if (suggestion.type === 'partNumber') {
      this.clearLocalStorage();
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['product-details/part-number/' + suggestion.value]);
      });
  } else if (suggestion.type === 'category') {
      this.clearLocalStorage();
      localStorage.setItem('selectedSparesList', JSON.stringify({"name": suggestion.value, "type": "categorySpares", "imageUrl": ""}));
      
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/spare-parts-list']);
      });
  } else if (suggestion.type === 'brand') {
      this.clearLocalStorage();
      localStorage.setItem('selectedSparesList', JSON.stringify({"name": suggestion.value, "type": "brandSpares", "imageUrl": ""}));
      
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/spare-parts-list']);
      });
  }else {            
      localStorage.setItem("preSearchValue", suggestion.value);
      this.clearLocalStorage();
      localStorage.setItem('selectedSparesList', JSON.stringify({"name": suggestion.value, "type": "allSpares", "imageUrl": ""}));
      
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/spare-parts-list']);
      });
  }
}

sortSuggestionsByRelevance(suggestions: string[], searchText: string): string[] {
  return suggestions?.sort((a, b) => {
      const relevanceA = this.getRelevance(a, searchText);
      const relevanceB = this.getRelevance(b, searchText);
      return relevanceB - relevanceA; // Higher relevance comes first
  });
}
getRelevance(suggestion: string, searchText: string): number {
  if (suggestion?.toString().toLowerCase() === searchText?.toLowerCase()) {
      return 3; // Exact match
  } else if (suggestion?.toString().toLowerCase().startsWith(searchText?.toLowerCase())) {
      return 2; // Starts with match
  } else if (suggestion?.toString().toLowerCase().includes(searchText?.toLowerCase())) {
      return 1; // Contains match
  } else {
      return 0; // No match
  }
}
isFirstSuggestion(suggestion: string, index: number): boolean {
  // Check if it's the first suggestion and if it matches preSearchValue
  let preSearchValue=localStorage.getItem('preSearchValue');
  return index === 0 && preSearchValue === suggestion['value'];
}

getHighlightedText(text: any, search: any): string {
  if (typeof text !== 'string' || typeof search !== 'string') {
      // Handle cases where text or search is not a string
      return text;
  }

  if (!search) {
      return text; // Return original text if no search term
  }

  const parts = text.split(new RegExp(`(${search})`, 'gi'));
  return parts.map(part => part.toLowerCase() === search.toLowerCase()
      ? `<span class="highlight">${part}</span>`
      : part
  ).join('');
}

  
}


