<div class="page-layout blank fullwidth p-12" fusePerfectScrollbar>
    <!-- <mat-progress-bar class="mt-8" *ngIf="loading" mode="indeterminate"></mat-progress-bar> -->
    <div class="mat-elevation-z8 mt-8 p-8 model">   
        
        <!-- Tabs -->
        <div class="row vehicle-detail-header">
            <button style="z-index: 11;background: none;border: none;cursor: pointer;width: 5%; margin-left: 12px;"  class="font-2x fw-600 pull-left text-red" (click)="goToMake()">
                <svg xmlns="http://www.w3.org/2000/svg" class=" filter-red  font-2x fw-600 pull-left  back-button">
                    <path  d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" />
                </svg>
            </button>
            <div class="vehicle-detail-label justify-content-center d-flex fw-600 text-center" style="cursor: pointer;" (click)="goToMake()">
                <div class="t-b ff-p" style="width: fit-content;">{{selectedMake}}
                    <hr class="non_active_hr">
                </div>
            </div>
            <div class="vehicle-detail-label justify-content-center d-flex text-center model_active">
                <div class="ff-p" style="width: fit-content;"> Model
                    <hr class="active_hr">
                </div>
            </div>
            <div class="vehicle-detail-label justify-content-center d-flex text-center ">
                <div lass="ff-p" style="width: fit-content;">Year
                    <hr class="non_active_hr">
                </div>
            </div>
            <div class="vehicle-detail-label justify-content-center d-flex text-center ">
                <div lass="ff-p" style="width: fit-content;">Variant
                    <hr class="non_active_hr">
                </div>
            </div>
        </div>
        <!-- End Tabs -->

        <h2 class="model-title">What's your car's model?</h2>

        <form class="searchModel-form">
            <input type="text" class ="search-bar" placeholder="Search for model" [(ngModel)]='searchText' name="searchText" autocomplete="off" (keyup)="searchFilterType()">
            <mat-icon *ngIf="openSearch" style="float: right; margin-top: 10px; z-index: 9999999; position: absolute; margin-left: -35px;">search</mat-icon>
            <mat-icon *ngIf="clearSearch" style="float: right; margin-top: 10px; z-index: 9999999; position: absolute; margin-left: -35px; cursor: pointer;" (click)="clearSearchFilter()">close</mat-icon>
        </form>       
        <div class="model-list-sec text-left">
            <div class="model-sec text-center"  *ngFor="let model of modelList|filter:searchText">
                <button *ngIf="selectedData==model.model" mat-raised-button class="model_name selected" (click)="selectModel(model.model)">{{model.model}}</button>                
                <button *ngIf="selectedData!=model.model" mat-raised-button class="model_name" (click)="selectModel(model.model)">{{model.model}}</button>                
            </div>
        </div>
    </div>
</div>
<div *ngIf="loading">
    <div #dynamicContainers></div>
    </div>