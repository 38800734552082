import { Component, AfterViewInit, ViewChild, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationPopupComponent } from '../cat-all/cat-all-popup/cat-all-popup.component';
import { CatAllService } from './cat-all.service';

@Component({
  selector: 'app-cat-all',
  templateUrl: './cat-all.component.html',
  styleUrls: ['./cat-all.component.scss']
})
export class CatAllComponent implements AfterViewInit {    
  @ViewChild('catalogImg', { static: false }) catalogImg: ElementRef;

  loading: boolean = false;
  carList: any = [];
  selectedCar: number = 4;  

  constructor(
    private catAllService: CatAllService,
    private el: ElementRef,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog
  ) { }

  pageNumber: number = 1;
  maker: any;
  model: any;
  year: any;
  variantId: any;
  
  // title: any = `${this.maker} ${this.model} ${this.variantId}`;
  
  croppedImageURL: any;
  pageTitle: any;
  carDetails: any = [];
  highlightAreas: any = [];
  activeId: any = '';

  isEditMode: boolean = false;
  startX: number = 0;
  startY: number = 0;
  endX: number = 0;
  endY: number = 0;  
  treeData: any = [];
  activeMenu: any = null;
  activeSubMenu: any = null;
  tLeft: any = '';
  tRight: any = '';
  bLeft: any = '';
  bRight: any = '';

  ngAfterViewInit() {
    this.loading = true; 
    this.getCarsList();  
    // this.catalogData();
    this.attachImageEventListeners();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateMarkerPositions();
  }

  catalogData() {   
    this.carDetails = [];
    this.highlightAreas = [];
    this.activeId = '';
    
    this.catAllService.getCatalogDetails(this.pageNumber, this.maker, this.model, this.year, this.variantId).subscribe(
      res => {
        this.loading = false;          
        let response = res;
        this.croppedImageURL = response[0].croppedImageURL;
        this.pageTitle = response[0].pageTitle;
        this.carDetails = response[0].carDetails;
        //do loop call id        
        Object.values(this.carDetails).forEach(list => {
          let refNoCoordinates = JSON.parse(list['refNoCoordinates']);
          let x = 0;
          let y = 0;
          if (refNoCoordinates && refNoCoordinates.length > 0) {
            x = refNoCoordinates[0].x;
            y = refNoCoordinates[0].y;
          }

          if (x !== 0 && y !== 0) {
            this.highlightAreas.push({ refNo: list['refNo'], x: x, y: y });
          }
        });
        this.updateMarkerPositions();
      }
    );
  }

  updateMarkerPositions() {
    if (this.catalogImg && this.catalogImg.nativeElement && this.catalogImg.nativeElement.complete) {
      const img = this.catalogImg.nativeElement;
      const imgWidth = img.clientWidth;
      const imgHeight = img.clientHeight;
      const originalWidth = img.naturalWidth;
      const originalHeight = img.naturalHeight;

      this.highlightAreas.forEach(area => {
        area.newX = (area.x / originalWidth) * imgWidth;
        area.newY = (area.y / originalHeight) * imgHeight;
      });
      this.cd.detectChanges();
    }
  }

  attachImageEventListeners() { 
    const imgElem = this.catalogImg.nativeElement;
    const coordinatesDisplay = document.getElementById('coordinates');
    const im2Elem = document.querySelector('.im2') as HTMLDivElement;
    const editModeToggle = document.getElementById('editModeToggle') as HTMLButtonElement;

    if (editModeToggle) {
      editModeToggle.addEventListener('click', () => {
        this.isEditMode = !this.isEditMode;
        const editButton = document.getElementById('editModeToggle') as HTMLButtonElement;
        if (editButton) {
          editButton.textContent = this.isEditMode ? 'Editing...' : 'Edit';
        }
      });
    }

    imgElem.addEventListener('mousedown', this.onMouseDown);    
    this.cd.detectChanges();
  }

  onMouseDown = (e) => {
    const imgElem = this.catalogImg.nativeElement;
    const im2Elem = document.querySelector('.im2') as HTMLDivElement;

    if (!this.isEditMode) return;

    let rect = imgElem.getBoundingClientRect();
    this.startX = e.clientX - rect.left;
    this.startY = e.clientY - rect.top;
    im2Elem.style.left = this.startX + 'px';
    im2Elem.style.top = this.startY + 'px';
    im2Elem.style.width = '0px';
    im2Elem.style.height = '0px';
    im2Elem.style.display = 'block';

    const mouseMoveHandler = (e) => {
      let rect = imgElem.getBoundingClientRect();
      this.endX = e.clientX - rect.left;
      this.endY = e.clientY - rect.top;
      im2Elem.style.width = Math.abs(this.endX - this.startX) + 'px';
      im2Elem.style.height = Math.abs(this.endY - this.startY) + 'px';
      im2Elem.style.left = Math.min(this.startX, this.endX) + 'px';
      im2Elem.style.top = Math.min(this.startY, this.endY) + 'px';
    };

    const mouseUpHandler = () => {
      imgElem.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
      
      let topLeft = { x: Math.min(this.startX, this.endX), y: Math.min(this.startY, this.endY) };
      let topRight = { x: Math.max(this.startX, this.endX), y: Math.min(this.startY, this.endY) };
      let bottomLeft = { x: Math.min(this.startX, this.endX), y: Math.max(this.startY, this.endY) };
      let bottomRight = { x: Math.max(this.startX, this.endX), y: Math.max(this.startY, this.endY) };
      
      const img = this.catalogImg.nativeElement;
      const imgWidth = img.clientWidth;
      const imgHeight = img.clientHeight;
      const originalWidth = img.naturalWidth;
      const originalHeight = img.naturalHeight;

      let scaleX = originalWidth / imgWidth;
      let scaleY = originalHeight / imgHeight;

      topLeft.x *= scaleX;
      topLeft.y *= scaleY;
      topRight.x *= scaleX;
      topRight.y *= scaleY;
      bottomLeft.x *= scaleX;
      bottomLeft.y *= scaleY;
      bottomRight.x *= scaleX;
      bottomRight.y *= scaleY;

      // const coordinatesMessage = 
      //   `Top Left: (${topLeft.x.toFixed(0)}, ${topLeft.y.toFixed(0)})\n` +
      //   `Top Right: (${topRight.x.toFixed(0)}, ${topRight.y.toFixed(0)})\n` +
      //   `Bottom Left: (${bottomLeft.x.toFixed(0)}, ${bottomLeft.y.toFixed(0)})\n` +
      //   `Bottom Right: (${bottomRight.x.toFixed(0)}, ${bottomRight.y.toFixed(0)})`;

      this.tLeft = topLeft;
      this.tRight = topRight;
      this.bLeft = bottomLeft;
      this.bRight = bottomRight;
      this.openConfirmationPopup();
        im2Elem.style.display = 'none';
      };


    imgElem.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  }

  openConfirmationPopup() {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '250px',
      data: {
        showInput: true,
        topLeft: this.tLeft,
        topRight: this.tRight,
        bottomLeft: this.bLeft,
        bottomRight: this.bRight,
        pageNumber: this.pageNumber,
        maker: this.maker,
        model: this.model,
        year: this.year,
        variantId: this.variantId
        // coordinates: coordinatesMessage
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirmed) {
        console.log('Confirmed with ID:', result.id);
        // console.log('Coordinates:',);
        // console.log('ID:', result.id);
        // Handle saving logic here
        // For example, save coordinates and ID to a server or local storage
      } else {
        console.log('Operation canceled');
      }
    });
  }
  

  showData(refNo) {
    this.activeId = refNo;
    setTimeout(() => {
      let selectedRow = this.el.nativeElement.querySelector(`#item-${refNo}`);
      if (selectedRow) {
        selectedRow.scrollIntoView();
      }
      this.cd.detectChanges();
    }, 500); 
  }

  pageSearch() {
    this.pageNumber = parseInt(this.el.nativeElement.querySelector('#pageNumber').value);
    this.ngAfterViewInit();
  }

  paginationPage(type, pageNo=0) {
    this.loading = true;
    
    setTimeout(() => {
      if (type === 'prev') {
        this.pageNumber--;
      } else if (type === 'next') {
        this.pageNumber++;
      }else if(type='treePage'){
        this.pageNumber = pageNo;
      }
    
      this.catalogData();
      this.loading = false;
    }, 500);
  }

  toggleMenu(category: any) {
    this.activeMenu = this.activeMenu === category ? null : category;
  }

  toggleMenuClose(category: any) {
    this.activeMenu = null;
    this.activeSubMenu = null;
  }
  
  toggleSubMenu(subMenu: any) {
    this.activeSubMenu = this.activeSubMenu === subMenu ? null : subMenu;  
  }

  getCarsList(){
    this.loading = true;  
    this.catAllService.getCarsList().subscribe(
      res => {
        this.loading = false; 
        this.carList = res;
        this.getSelectedCarData();
      }
    );
  }

  onSelectChangeCar(event){
    this.getSelectedCarData();
  }

  getSelectedCarData(){
    this.pageNumber = 1;
    this.maker = '';
    this.model = '';
    this.year = '';
    this.variantId = '';
  
    this.croppedImageURL = '';
    this.pageTitle = '';
    this.carDetails = [];
    this.highlightAreas = [];
    this.activeId = '';
    this.treeData = [];

    if(this.selectedCar){
      this.carList.forEach(cars => {
        if(cars.id == this.selectedCar){
          this.maker = cars.maker;
          this.model = cars.model;
          this.year = cars.manufacturingYear;
          this.variantId = cars.variantId;
        }
      });
    }
    this.getTreeData();
    this.catalogData();
  }

  getTreeData() {
    this.loading = true;
    this.catAllService.getTreeData(this.selectedCar).subscribe(
      res => {
        this.loading = false;  
        
        Object.entries(res).forEach(([key, treeData]) => {
          let data = [];
          Object.entries(treeData).forEach(([name, pages]) => {
            data.push({"pageNo": pages, "pageTitle": name});
          });

          this.treeData.push({"key": key, "values": data});
        });
      }
    );
  }
}
