import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'app/main/globals';

@Injectable({
  providedIn: 'root'
})
export class AspHomeService {
  paramData: any;
  constructor(
    private http: HttpClient,

  ) { }
  
  popularBrandsAndCategory(){
      this.paramData = {
        authKey: GlobalVariable.AUTH_KEY,
      };

      // console.log(this.paramData);
      console.log('-----Popular Brands And Category-----------');    
      return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/spare/popularBrandsAndCategory', this.paramData);
  }

  getAllCategories(){
    this.paramData = {
      authKey: GlobalVariable.AUTH_KEY
    };

    // console.log(this.paramData);
    console.log('-----Popular Brands And Category-----------');    
    return this.http.post(`${GlobalVariable.BASE_API_URL_SPARES}` + '/spare/categories/all', this.paramData);
  }

  

  regDecode(regNo){
    this.paramData = {};

    // console.log(this.paramData);
    console.log('-----Registration Decode-----------');    
    return this.http.get(`${GlobalVariable.BASE_API_URL_SPARES}` + '/vehicle/decode/'+regNo, this.paramData);
  }


}
