<div class="page-layout blank fullwidth p-12" fusePerfectScrollbar>
    <mat-progress-bar class="mt-8" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    
    <div class="mat-elevation-z8 mt-8">  
        
        <div class="container">
            <div class="head-sec">
                <!-- <div id="page-title">{{title}}</div> -->
                <div id="page-title">
                    <select name="selectedCar" id="selectedCar" [(ngModel)]="selectedCar" (ngModelChange)="onSelectChangeCar($event)">
                        <option *ngFor="let car of carList" [value]="car.id">{{car.maker}} {{car.model}} {{car.variantId}}</option>
                    </select>
                </div>
                <div id="page-title-category">
                    <i class="material-icons" name="prev" id="prev" (click)="paginationPage('prev')" style="width:80px">chevron_left</i>
                    {{pageTitle}}
                    <i class="material-icons" name="next" id="next" (click)="paginationPage('next')" style="width:80px">chevron_right</i>
                </div>
                <div id="page-search">
                    <label>Jump To </label>
                    <input type="text" name="pageNumber" id="pageNumber" [value]="pageNumber"/>
                    <button type="button" name="submit" id="submit" (click)="pageSearch()">Submit</button>
                </div>
            </div>

            <div class="image-table-sec">
                <div class="image-inputs">
                    <!-- Hierarchical Menu -->
                    <div class="left-column">
                        
                        <div class="menu">
                          <div *ngFor="let category of treeData" class="menu-item" >
                            <div *ngIf="activeMenu != category.key" (click)="toggleMenu(category.key)">
                                <mat-icon class="tree-icon">add_box</mat-icon>
                                <span>{{ category.key }}</span>
                            </div>
                            <div *ngIf="activeMenu == category.key" (click)="toggleMenuClose(category.key)">
                                <mat-icon class="tree-icon">indeterminate_check_box</mat-icon>
                                <span>{{ category.key }}</span>
                            </div>
                            <div *ngIf="activeMenu == category.key" class="sub-menu">
                              <div *ngFor="let component of category.values" class="sub-menu-item" (click)="toggleSubMenu(component.pageTitle)">
                                <mat-icon class="tree-icon" *ngIf="activeSubMenu != component.pageTitle">add_box</mat-icon>
                                <mat-icon class="tree-icon" *ngIf="activeSubMenu == component.pageTitle">indeterminate_check_box</mat-icon>
                                <span>{{ component.pageTitle }}</span>
                                <div *ngIf="activeSubMenu === component.pageTitle" class="sub-sub-menu">
                                    <div class="tree-page" *ngFor="let page of component.pageNo" (click)="paginationPage('treePage', page)">Page {{ page }}</div>
                                </div>
                            </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
                

                <div class="image-container">
                    <div class="im2"></div>
                    <div id="coordinates">Coordinates: (0, 0)</div>
                    <button  id="editModeToggle" class="edit-icon" >✏ Edit</button>
                    <!-- (click)="attachImageEventListeners()" -->
                      
                    <img #catalogImg id="image" [src]="croppedImageURL" alt="Catalog Image" (load)="updateMarkerPositions()" >
                    <span *ngFor="let area of highlightAreas"
                        class="highlight-area"
                        [style.left.px]="area.newX"
                        [style.top.px]="area.newY"
                        [attr.data-x]="area.x"
                        [attr.data-y]="area.y"
                        (click)="showData(area.refNo)">
                    </span>
                </div>
                <div class="item-details">
                    <table>
                        <tr>
                            <th>REF NO</th>
                            <th>PART NO</th>
                            <th>DESCRIPTION</th>
                            <th>QTY</th>
                            <th>REMARKS</th>
                        </tr>
                        <tr *ngFor="let list of carDetails" [id]="'item-'+list.refNo" [class.active]="list.refNo === activeId">
                            <td>{{list.refNo}}</td>
                            <td>{{list.partNo}}</td>
                            <td>{{list.description}}</td>
                            <td>{{list.qty}}</td>
                            <td>{{list.remarks}}</td>
                        </tr>
                    </table>
                   
                </div>
            </div>
        </div>
    </div>
</div>